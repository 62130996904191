import {toQueryString} from '@common/utils/utils';
import isArray from 'lodash/isArray';
import isBoolean from 'lodash/isBoolean';
import isDate from 'lodash/isDate';
import isNumber from 'lodash/isNumber';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';
import mapKeys from 'lodash/mapKeys';
import moment from 'moment';

const NetworkAPIVersion = {
  v1_version: '/api/v1/',
  v2_version: '/api/v2/',
  v20_version: '/api/v20/',
  noVersion: '',
};

const NetworkAPIBase = process.env.VUE_APP_API_BASE;
const NetworkAPIThumbnailURL = NetworkAPIBase + 'api/s3-thumbnail/';
const getNetworkAPIAssetResolveURL = assetId => `${NetworkAPIBase}api/v1/asset/${assetId}/forward/`;

function getNetworkAPIZupuImageThumbnailURL(zupuImageId, height = null, width = null) {
  let str = `${NetworkAPIBase.slice(0, -1)}${
    NetworkAPIVersion.v1_version
  }clan-pedigree-image/${zupuImageId}/thumbnail/`;
  const queryParamsStr = toQueryString({width, height});
  return `${str}?${queryParamsStr}`;
}

function getSourceImageThumbnailResolveURL(sourceId, priority) {
  return `${NetworkAPIBase}api/v1/sources/${sourceId}/image-thumbnail/${priority}`;
}

class NetworkParamTypeClass {
  constructor(type) {
    this.type = type;
    switch (type) {
      case 'array':
        this.validate = data => isArray(data);
        break;
      case 'filesArray':
        this.validate = data => isArray(data);
        break;
      case 'string':
        this.validate = data => isString(data);
        break;
      case 'number':
        this.validate = data => isNumber(data);
        break;
      case 'boolean':
        this.validate = data => isBoolean(data);
        break;
      case 'date':
        this.validate = data => {
          // could be [ timestamp, string, date, moment]
          if (isNumber(data) || isString(data) || isDate(data) || moment.isMoment(data)) {
            try {
              new moment(data);
              return true;
            } catch (e) {
              console.error(e);
              return false;
            }
          } else {
            return false;
          }
        };

      case 'object':
        this.validate = data => isObject(data);
        break;
    }
  }
}

const NetworkParamType = {
  array: new NetworkParamTypeClass('array'),
  dynamicArray: new NetworkParamTypeClass('dynamicArray'),
  string: new NetworkParamTypeClass('string'),
  number: new NetworkParamTypeClass('number'),
  boolean: new NetworkParamTypeClass('boolean'),
  date: new NetworkParamTypeClass('date'),
  file: new NetworkParamTypeClass('file'),
  filesArray: new NetworkParamTypeClass('filesArray'),
  object: new NetworkParamTypeClass('object'),
};

const _filterValidateParams = (actionParams, requestParams) => {
  actionParams = actionParams || {};
  var filteredParams = {};

  Object.keys(actionParams).forEach((paramName, index) => {
    /* fact1_place_id, fact2_value */
    if (actionParams[paramName].includes(NetworkParamType.dynamicArray)) {
      filteredParams = {...filteredParams, ...requestParams[paramName]};
      return;
    }

    if (!isUndefined(requestParams[paramName])) {
      // TODO  convert all date type in format here.
      filteredParams[paramName] = requestParams[paramName];
    }
  });

  // make exceptions for files
  mapKeys(requestParams, (value, key) => {
    if (value instanceof File) {
      filteredParams[key] = value;
    }
  });

  return filteredParams;
};

export {
  _filterValidateParams,
  getNetworkAPIAssetResolveURL,
  getNetworkAPIZupuImageThumbnailURL,
  getSourceImageThumbnailResolveURL,
  NetworkAPIBase,
  NetworkAPIThumbnailURL,
  NetworkAPIVersion,
  NetworkParamType,
};
