export default {
  uploadImageState(state) {
    return state.uploadImage;
  },
  imageToTextState(state) {
    return state.imageData;
  },
  imageToTextLoadingState(state) {
    return state.imageDataLoading;
  },
};
