import {NetworkAPIVersion, NetworkParamType} from './network.utils';

export default {
  saveMention: {
    url: 'saved-mention/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    params: {
      mention_id: [NetworkParamType.string],
      person_id: [NetworkParamType.string],
      family_tree_id: [NetworkParamType.string],
      saved_mention_id: [NetworkParamType.string] /* to update existing mention */,
    },
  },
  getSavedMentions: {
    url: 'saved-mention/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
    params: {
      person_id: [NetworkParamType.string],
      limit: [NetworkParamType.string],
      offset: [NetworkParamType.string],
    },
  },
  deleteSavedMention: {
    url: 'saved-mention/{id}/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'delete',
  },
  getSavedMentionsPersonIds: {
    url: 'saved-mention/person-ids/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
    params: {
      family_tree_id: [NetworkParamType.string],
    },
  },
};
