export default {
  setOwnedClanPedigreeListState(state, list) {
    state.list = list;
  },
  setOwnedClanPedigreeListMetaState(state, meta) {
    state.listMeta = meta;
  },
  setOwnedClanPedigreeListLoadingState(state, loading) {
    state.listLoading = loading;
  },
  setBuyZupuLoadingState(state, loading) {
    state.buyZupuLoading = loading;
  },
};
