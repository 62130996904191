import network from '@common/network/network';

import {getFamilyTreeClanModalReviewedIdentifier} from './utils';

export default {
  reviewFamilyTreeClanModalAction(context, {familyTreeId, clanSurname}) {
    context.commit('addReviewedItemState', getFamilyTreeClanModalReviewedIdentifier(familyTreeId, clanSurname));
  },
  getNotificationsAction(context) {
    return new Promise((resolve, reject) => {
      network.member.getNotifications().then(response => {
        context.commit('setNotificationsState', response);
        resolve(response);
      });
    });
  },
};
