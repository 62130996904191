<template>
  <div id="notifications-container">
    <transition :name="transitionName">
      <div class="notifications-container-fixed" v-if="notification">
        <component
          ref="component"
          class="notification shown"
          :is="notification.component"
          v-bind="notification.props"
          @close="hide"
        ></component>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  created() {
    this.$root._notificationsContainer = this;
  },
  data() {
    return {
      notification: null,
    };
  },
  computed: {
    isDesktop() {
      return this.$store.getters.windowWidthState >= this.$breakpoints.phablet;
    },
    transitionName() {
      return this.isDesktop ? 'info-bar-slide-right' : 'info-bar-slide-bottom';
    },
  },
  methods: {
    show(component, props) {
      this.notification = {component, props};
    },
    hide() {
      this.$nextTick(() => {
        this.notification = null;
      });
    },
  },
  name: 'NotificationsContainer',
};
</script>

<style scoped lang="scss"></style>
