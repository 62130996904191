/* eslint-disable no-unused-vars */
import {prerenderFor, prerenderIf} from '@common/utils/directives/definitions';
import each from 'lodash/each';
import upperFirst from 'lodash/upperFirst';
import Vue from 'vue';

Vue.directive('click-outside', {
  bind: function (el, binding, vNode) {
    const bubble = binding.modifiers.bubble;
    const handler = e => {
      if (bubble || (!el.contains(e.target) && el !== e.target)) {
        binding.value(e);
      }
    };
    el.__vueClickOutside__ = handler;
    document.addEventListener('click', handler);
  },

  unbind: function (el, binding) {
    document.removeEventListener('click', el.__vueClickOutside__);
    el.__vueClickOutside__ = null;
  },
});

Vue.directive('right-click-outside', {
  bind: function (el, binding, vNode) {
    const bubble = binding.modifiers.bubble;
    const handler = e => {
      if (bubble || (!el.contains(e.target) && el !== e.target)) {
        binding.value(e);
      }
    };
    el.__vueRightClickOutside__ = handler;
    document.addEventListener('contextmenu', handler);
  },

  unbind: function (el, binding) {
    document.removeEventListener('contextmenu', el.__vueRightClickOutside__);
    el.__vueRightClickOutside__ = null;
  },
});

Vue.directive('clickOutsideHide', {
  bind(el, binding, vnode) {
    let documentHandler = e => {
      let flag = false;
      // 这里判断点击的元素的父级中是否有 ‘data-filter="click-no-hide"’
      each(e.path, (elem, index) => {
        if (elem.dataset && elem.dataset.filter == 'click-no-hide') {
          flag = true;
        }
      });

      // 这里判断点击的元素是否是本身，是本身，则返回
      if (el.contains(e.target) || flag) {
        return false;
      }
      // 判断指令中是否绑定了函数
      if (binding.expression) {
        // 如果绑定了函数 则调用那个函数，此处binding.value就是hideFilterList方法
        binding.value(e);
      }
    };

    let documentHandlerScroll = e => {
      let flag = false;
      // 这里判断点击的元素的父级中是否有 ‘data-filter="click-no-hide"’
      each(e.path, (elem, index) => {
        if (elem.dataset && elem.dataset.filter == 'click-no-hide') {
          flag = true;
        }
      });

      if (binding.expression) {
        // 如果绑定了函数 则调用那个函数，此处binding.value就是hideFilterList方法
        binding.value(e);
      }
    };
    // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
    el.__vueClickOutside__ = documentHandler;
    el.__vueScrollOutSide__ = documentHandlerScroll;
    document.addEventListener('click', documentHandler);
    document.addEventListener('scroll', documentHandlerScroll);
  },
  unbind(el, binding) {
    // 解除事件监听
    document.removeEventListener('click', el.__vueClickOutside__);
    document.removeEventListener('scroll', el.__vueScrollOutSide__);
    delete el.__vueClickOutside__;
    delete el.__vueScrollOutSide__;
  },
});

Vue.directive('prerender-for', prerenderFor);
Vue.directive('prerender-if', prerenderIf);

Vue.directive('capitalize', {
  bind(el, binding, vNode) {
    const handler = e => {
      const values = el.value ? el.value.split(' ') : [];
      const capitalized = values.map(word => upperFirst(word)).join(' ');
      if (capitalized !== el.value) {
        el.value = capitalized;
        el.dispatchEvent(new Event('input', {bubbles: true}));
      }
    };
    el.__capitalizeOnInput__ = handler;
    document.addEventListener('change', handler);
  },
  unbind(el, binding) {
    document.removeEventListener('change', el.__capitalizeOnInput__);
    el.__capitalizeOnInput__ = null;
  },
});
