import network from '@common/network/network';

export default {
  createParenthoodAction(context, {parent_ids, child_ids}) {
    context.commit('setParenthoodCreateLoadingState', true);
    return new Promise((resolve, reject) => {
      network.familyTreeRelations
        .createParenthood({parent_ids, child_ids})
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          context.commit('setParenthoodCreateErrorsState', error.response.data.error);
          reject(error.response.data);
        })
        .finally(() => {
          context.commit('setParenthoodCreateLoadingState', false);
        });
    });
  },
  createCoupleAction(
    context,
    {person1_id, person2_id, marriage_place, marriage_date, is_divorced, divorce_place, divorce_date}
  ) {
    context.commit('setCoupleCreateLoadingState', true);
    return new Promise((resolve, reject) => {
      network.familyTreeRelations
        .createCouple({person1_id, person2_id, marriage_place, marriage_date, is_divorced, divorce_place, divorce_date})
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          context.commit('setCoupleCreateErrorsState', error.response.data.error);
          reject(error.response.data);
        })
        .finally(() => {
          context.commit('setCoupleCreateLoadingState', false);
        });
    });
  },
  updateCoupleAction(context, {id, marriage_place, marriage_date, is_divorced, divorce_place, divorce_date}) {
    context.commit('setCoupleUpdateLoadingState', true);
    return new Promise((resolve, reject) => {
      network.familyTreeRelations
        .updateCouple({id, marriage_place, marriage_date, is_divorced, divorce_place, divorce_date})
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error.response.data);
        })
        .finally(() => {
          context.commit('setCoupleUpdateLoadingState', false);
        });
    });
  },
};
