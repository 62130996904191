<template>
  <div class="staging-env-label" v-if="checkEnv">
    {{ envName }}
  </div>
</template>

<script>
import consts from '@common/utils/consts';
import {isCypressEnv} from '@common/utils/utils-e2e';

export default {
  data() {
    return {
      envName: process.env.VUE_APP_PROFILE,
    };
  },
  computed: {
    checkEnv() {
      return false;
      if (this.$route.meta && (this.$route.meta.isJLTReport || this.$route.meta.isFWSReport)) {
        return false;
      }
      return process.env.VUE_APP_PROFILE != consts.PROD_PROFILE && !isCypressEnv();
    },
  },
};
</script>

<style lang="scss" scoped>
.staging-env-label {
  background-color: $staging-color;
  color: #fff;
  padding: 8px;
  box-shadow: $box-shadow;
}
</style>
