export default {
  placeAlternatives: null,
  placeAlternativesLoading: false,

  placeSearch: null,
  placeSearchMeta: {},
  placeSearchLoading: false,
  placeSearchStartTime: null,
  placeSearchQuery: '',
  placeSearchRootPlace: {},
  placeSearchMigrationPlace: {},
  placeSearchClan: {},
  placeSearchLevel: null,

  placeOverseasSearch: null,
  placeOverseasSearchLoading: null,
  placeOverseasSearchMeta: {},
  placeOverseasSearchQuery: '',

  place: {},
  placeLoading: false,
  placeDescendantsSearch: null,
  placeDescendantsSearchMeta: {},
  placeDescendantsSearchLoading: {},
};
