import {NetworkAPIVersion, NetworkParamType} from './network.utils';

export default {
  list: {
    url: 'order/',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,
    params: {
      limit: [NetworkParamType.number],
      offset: [NetworkParamType.number],
    },
  },
};
