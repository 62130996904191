import basicGetters from '@common/store/basic/getters';
import {getDatesDiffInDays} from '@common/utils/utils';

export default {
  registrationWallTextState(state) {
    return state.registrationWallText;
  },
  loginPageTextState(state) {
    return state.loginPageText || 'Log in to your account.';
  },
  usersCountDisplayState(state) {
    const daysPassed = getDatesDiffInDays(state.usersCountStartDate, new Date());
    const count = state.usersCount + state.usersCountGrowthPerDay * daysPassed;
    return count.toLocaleString();
  },
  quickSidebarPropsState(state) {
    return state.quickSidebarProps || {};
  },
  quickSidebarIdState(state) {
    return state.quickSidebarProps ? state.quickSidebarProps.id : null;
  },
  ...basicGetters,
};
