export default {
  familyTreeSavedMentionsState(state) {
    return state.savedMentions || [];
  },
  familyTreeSavedMentionsMetaState(state) {
    return state.savedMentionsMeta || {};
  },
  familyTreeSavedMentionsLoadingState(state) {
    return state.savedMentionsLoading;
  },

  familyTreePersonSavedMentionsState(state) {
    return state.savedMentionsPerson || [];
  },
  familyTreePersonSavedMentionsMetaState(state) {
    return state.savedMentionsPersonMeta || {};
  },
  familyTreePersonSavedMentionsLoadingState(state) {
    return state.savedMentionsPersonLoading;
  },

  familyTreeSavedMentionsPersonIdsState(state) {
    return state.savedMentionsPersonIds || [];
  },
};
