import axios from 'axios';

export class Network {
  constructor(apiBaseUrl) {
    this.api = axios.create({
      withCredentials: true,
      baseURL: apiBaseUrl,
      timeout: 5 * 60000,
    });
  }
}
