<template>
  <div class="cny-banner-subscription" v-if="showBanner" @click="hideBanner">
    <router-link class="content-link" :to="plansRoute">
      <div class="content">
        <div class="emoji">❄️</div>
        <div class="text">Holiday Sale: Subscribe now and enjoy 50% off your first year!</div>
        <div class="text-mobile text-lg text-lg-mobile">Holiday Sale: Enjoy 50% off your first year!</div>
        <div class="emoji">❄️</div>
      </div>
    </router-link>
    <div class="close"><close-icon :size="25" /></div>
  </div>
</template>

<script>
import CloseIcon from 'vue-material-design-icons/Close';
import {mapGetters} from 'vuex';

const BANNER_NAME = 'HOLIDAY24_SALE_BANNER_SUBSCRIPTION';

export default {
  watch: {
    showBanner(value) {
      this.$emit('change-visibility', value);
    },
  },
  computed: {
    ...mapGetters(['reviewedItemsState', 'featureSwitchesState', 'userIsSubscribedState']),
    showBanner() {
      return (
        this.featureSwitchesState.HOLIDAY24_SALE &&
        !this.userIsSubscribedState &&
        !this.reviewedItemsState.includes(BANNER_NAME)
      );
    },
    plansRoute() {
      return {name: 'subscription-plans'};
    },
  },
  methods: {
    hideBanner() {
      this.$store.commit('addReviewedItemState', BANNER_NAME);
    },
  },
  components: {CloseIcon},
  name: 'xmasBannerSubscription',
};
</script>

<style scoped lang="scss">
.cny-banner-subscription {
  background-color: $primary-600;
  height: 76px;
  display: flex;
  box-shadow: $box-shadow;
  position: relative;
  z-index: 8;
  width: 100%;

  .content-link {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    &:hover {
      text-decoration: none;
    }
  }

  .content {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    column-gap: 16px;
    padding: 0 24px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  }

  .text {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0.005em;
    text-align: center;
    color: #ffffff;
  }
  .text-mobile {
    display: none;
    letter-spacing: 0.005em;
    text-align: center;
    color: #ffffff;
    font-weight: 700;
  }

  .emoji {
    font-size: 28px;
    font-weight: 400;
    line-height: 34px;
  }

  .close {
    position: absolute;
    color: white;
    right: 30px;
    top: 25px;
    cursor: pointer;
  }

  @media only screen and (max-width: $main-menu-breakpoint) {
    height: 104px;

    .content-link {
      align-items: flex-end;
      margin-bottom: 12px;
    }

    .text {
      display: none;
    }
    .text-mobile {
      display: block;
    }

    .emoji {
      font-size: 24px;
      font-weight: 400;
      line-height: 29px;
      letter-spacing: 0.005em;
      text-align: left;
    }

    .close {
      top: 13px;
      right: 17px;
    }
  }
}
</style>
