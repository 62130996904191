export default {
  treeOnboardingDataState(state) {
    return state.onboardingData;
  },
  treeOnboardingDataLoadingState(state) {
    return state.onboardingDataLoading;
  },
  treeOnboardingUpdateLoadingState(state) {
    return state.onboardingUpdateLoading;
  },
  treeOnboardingSuccessMessageState(state) {
    return state.onboardingSuccessMessage;
  },

  treeUsageTutorialState(state) {
    return state.treeUsageTutorial;
  },
};
