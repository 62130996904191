export default {
  wikiArticleState(state) {
    return state.article;
  },
  wikiArticleLoadingState(state) {
    return state.articleLoading;
  },
  wikiCategoryState(state) {
    return state.category;
  },
  wikiCategoryLoadingState(state) {
    return state.categoryLoading;
  },
  wikiSearchState(state) {
    return state.search || [];
  },
  wikiSearchMetaState(state) {
    return state.searchMeta;
  },
  wikiSearchLoadingState(state) {
    return state.searchLoading;
  },
  wikiSearchFormQueryState(state) {
    return state.searchFormQuery;
  },

  wikiEventsState(state) {
    return state.wikiEvents;
  },
  wikiEventsLoadingState(state) {
    return state.wikiEventsLoading;
  },
};
