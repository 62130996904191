import basicState from '@common/store/basic/state';

export default {
  registrationWallText: null,
  loginPageText: null,

  usersCount: 5135,
  usersCountStartDate: new Date('2021/9/8 00:00:00'),
  usersCountGrowthPerDay: 9,
  quickSidebarProps: null,
  ...basicState,
};
