export default {
  setFamilyWebsiteListState(state, list) {
    state.list = list;
  },
  setFamilyWebsiteListLoadingState(state, loading) {
    state.listLoading = loading;
  },
  setFamilyWebsiteDetailsState(state, details) {
    state.details = details;
  },
  setFamilyWebsiteDetailsLoadingState(state, loading) {
    state.detailsLoading = loading;
  },
};
