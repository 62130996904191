import {NetworkAPIVersion, NetworkParamType} from './network.utils';

export default {
  list: {
    url: 'saved-clan-pedigree/',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,
    params: {
      limit: [NetworkParamType.number],
      offset: [NetworkParamType.number],
      family_tree_id: [NetworkParamType.number],
      clan_name: [NetworkParamType.string],
      is_family_tree_set: [NetworkParamType.string], // '0' or '1'
    },
  },
  create: {
    url: 'saved-clan-pedigree/',
    method: 'post',
    apiVersion: NetworkAPIVersion.v1_version,
    params: {
      clan_pedigree_id: [NetworkParamType.string],
      family_tree_id: [NetworkParamType.number],
      clan_name: [NetworkParamType.string],
    },
  },
  update: {
    url: 'saved-clan-pedigree/{id}/',
    method: 'patch',
    apiVersion: NetworkAPIVersion.v1_version,
    params: {
      family_tree_id: [NetworkParamType.number],
      clan_name: [NetworkParamType.string],
    },
  },
  delete: {
    url: 'saved-clan-pedigree/{id}/',
    method: 'delete',
    apiVersion: NetworkAPIVersion.v1_version,
  },
};
