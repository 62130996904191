import {NetworkAPIVersion, NetworkParamType} from '@common/network/network.utils';

export default {
  list: {
    url: 'person-hints/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
    autoCancel: true,
    params: {
      family_tree_id: [NetworkParamType.string],
      person_id: [NetworkParamType.string],
      status: [NetworkParamType.string],
      limit: [NetworkParamType.number],
      offset: [NetworkParamType.number],
    },
  },
  details: {
    url: 'person-hints/{id}/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
  },
  ignore: {
    url: 'person-hints/{id}/ignore/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
  },
  accept: {
    url: 'person-hints/{id}/accept/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    params: {
      data: [NetworkParamType.object],
    },
  },
  personIds: {
    url: 'person-hints/person-ids/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
    autoCancel: true,
    params: {
      family_tree_id: [NetworkParamType.string],
    },
  },
};
