export default {
  setGalleryItemsState(state, items) {
    state.items = items;
  },
  setGalleryMetaState(state, meta) {
    state.meta = meta;
  },
  setGalleryCurrentIndexState(state, index) {
    if (index >= 0 && index < state.items.length) {
      state.currentIndex = index;
    }
  },
  removeItemByIdFromGalleryState(state, id) {
    state.items = state.items.filter(item => item._id !== id);
    if (state.currentIndex !== null && !state.items[state.currentIndex]) {
      state.currentIndex = state.currentIndex > 0 ? state.currentIndex - 1 : null;
    }
  },
};
