export default {
  familyTreeHintsListState(state) {
    return state.list || [];
  },
  familyTreeHintsLoadingState(state) {
    return state.loading;
  },
  familyTreeHintsMetaState(state) {
    return state.meta || {};
  },

  familyTreeHintDetailsState(state) {
    return state.details || {};
  },
  familyTreeHintDetailsLoadingState(state) {
    return state.detailsLoading;
  },
  familyTreeHintDetailsPreviousRouteState(context) {
    return context.detailsPreviousRoute;
  },
  familyTreeHintIgnoreLoading(context) {
    return context.ignoreLoading;
  },
  familyTreeHintAcceptLoading(context) {
    return context.acceptLoading;
  },

  familyTreeHintsCountsByPersonIdsState(state, getters) {
    return getters.isUserOwnerFamilyTreeState ? state.newByPersonIds : {};
  },
};
