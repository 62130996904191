<template>
  <router-link :to="to" class="mcr-button mcr-button-router-link">
    <div class="mcr-button-label" @click="click" @click.ctrl="click" @click.meta="click" @click.middle="click">
      <slot>{{ label }}</slot>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    to: Object,
    label: String,
  },
  methods: {
    click() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@common/style/mcr-button';
</style>
