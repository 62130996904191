export default {
  teamMembersState(state) {
    return state.teamMembers;
  },
  advisorMembersState(state) {
    return state.advisorMembers;
  },
  researchMembersState(state) {
    return state.researchMembers;
  },
};
