import network from '@common/network/network';
import AnalyticsFacebookPixelHandler from '@common/utils/analytics/analytics.facebook.pixel';
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';

export default {
  searchClansSurnamesAction(context, {q, page}) {
    const limit = 10;
    const offset = limit * (page - 1);
    let params = {offset, limit, q, country_region_id: null, dialect_region_id: null};

    context.commit('setSurnameClansSearchLoadingState', true);
    return new Promise((resolve, reject) => {
      network.clan
        .searchClanRegion(params)
        .then(response => {
          context.commit('setSurnameClansSearchListState', response.objects);
          resolve(response);
        })
        .finally(() => {
          context.commit('setSurnameClansSearchLoadingState', false);
        });
    });
  },
  searchSurnamesAction(context, {start, page}) {
    const limit = 100;
    const offset = limit * ((page || 1) - 1);
    context.commit('setSurnamesLoadingState', true);
    return new Promise((resolve, reject) => {
      network.clanSurname
        .search({start, limit, offset})
        .then(response => {
          context.commit('setSurnamesListState', response.objects);
          context.commit('setSurnamesMetaState', response.meta);
          resolve(response);
        })
        .finally(() => {
          context.commit('setSurnamesLoadingState', false);
        });
    });
  },
  getSurnameExistsAction(context, surname) {
    context.commit('setSurnameExistsLoadingState', true);
    return new Promise((resolve, reject) => {
      network.clan.getSurnameExists({surname}).then(response => {
        context.commit('setSurnameExistsState', response);
        context.commit('setSurnameExistsLoadingState', false);
        AnalyticsFacebookPixelHandler.trackSearchEvent({contentCategory: 'Surnames'});
        AnalyticsMainHandler.trackSurnameSearchSubmit({q: surname}, response ? 1 : 0);

        resolve(response);
      });
    });
  },
};
