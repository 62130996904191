import {getAsset} from '@common/utils/utils';

let villages = {
  name: 'Villages',
  imgSrc: getAsset('/assets/services/ancestry/polaroids/Traces_Villages.jpg'),
  details_html: `
    <p>Where did your ancestors live before migrating overseas? How many generations of your family still remain in China? Finding your village can help you find more relatives, stories, and records of your ancestors.</p>
  `,
};
let relatives = {
  name: 'Relatives',
  imgSrc: getAsset('/assets/services/ancestry/polaroids/Traces_Relatives.jpg'),
  details_html: `
    <p>When your ancestors left China, they probably planned to return one day. They may have left behind siblings, wives, and children, whose descendants may still be there today, patiently awaiting your return.</p>
  `,
};
let familyTreeBooks = {
  name: 'Family Tree Books',
  imgSrc: getAsset('/assets/services/ancestry/polaroids/Traces_Zupus.jpg'),
  details_html: `
    <p>The Holy Grail of Chinese genealogy, family tree books are privately compiled records of clan lineages in your ancestral village. Known as zupu 族谱 or jiapu 家谱, they often go back hundreds of years, documenting names, migration histories, clan rules, even biographies of famous ancestors!</p>
  `,
};
let graves = {
  name: 'Graves',
  imgSrc: getAsset('/assets/services/ancestry/polaroids/Traces_Graves.jpg'),
  details_html: `
    <p>Ancestors were typically buried near the village in areas with good fengshui. Headstones offer valuable clues, but graveyards may prove difficult to access until the Tomb-Sweeping Festival, when locals pay their respects and clear the overgrown paths.</p>
  `,
};
let houses = {
  name: 'Houses',
  imgSrc: getAsset('/assets/services/ancestry/polaroids/Traces_Houses.jpg'),
  details_html: `
   <p>It’s possible that the house your ancestors lived in is still standing today. See where they grew up, who lives there now, and if any traces of them remain.</p>
  `,
};
let oralHistories = {
  name: 'Oral Histories',
  imgSrc: getAsset('/assets/services/ancestry/polaroids/Traces_Oral.jpg'),
  details_html: `
    <p>It’s likely that the achievements and struggles of your ancestors were whispered by those who stayed behind. Perhaps the money they sent back has had lasting community impact. What memories still remain among the villagers today?</p>
  `,
};
let temples = {
  name: 'Temples',
  imgSrc: getAsset('/assets/services/ancestry/polaroids/Traces_Temples.jpg'),
  details_html: `
    <p>The ancestral temple, known as the clan or ancestral hall (祠堂 citang), was the center of all public life. From rituals to festivals, villagers gathered here for banquets, worship, weddings, funerals, and local elections.</p>
  `,
};
let more = {
  name: 'And More!',
  imgSrc: getAsset('/assets/services/ancestry/polaroids/Traces_More.jpg'),
  details_html: `
    <p>We might find letters, family portraits, gazetteers, land deeds, and ancestral tablets. We leave no stone unturned!</p>
  `,
};

export default {
  traces: [villages, relatives, familyTreeBooks, graves, houses, oralHistories, temples, more],
};
