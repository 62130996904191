import network from '@common/network/network';

export default {
  fetchOrdersAction(context, {page}) {
    const limit = 10;
    const offset = limit * (page - 1);
    context.commit('setOrdersLoadingState', true);
    return new Promise((resolve, reject) => {
      network.order
        .list({limit, offset})
        .then(response => {
          context.commit('setOrdersMetaState', response.meta);
          context.commit('setOrdersListState', response.objects);
        })
        .finally(() => {
          context.commit('setOrdersLoadingState', false);
        });
    });
  },
};
