// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import LoadingIndicator from '@common/elements/loading/loadingIndicator';
import network from '@common/network/network';
import {breakpoints} from '@common/plugins/breakpoints';
import AnalyticsFacebookPixelHandler from '@common/utils/analytics/analytics.facebook.pixel';
import AnalyticsHubspotHandler from '@common/utils/analytics/analytics.hubspot';
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import {getRoutePageIdentifier, getRoutePageName, isBotUserAgent} from '@common/utils/analytics/utils.analytics';
import '@common/utils/directives/vue-directives';
import {handleImpersonationOnRouteChange} from '@common/utils/impersonation';
import {initHotjar, initHubspot} from '@common/utils/script.loader';
import {getAsset} from '@common/utils/utils';
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import pick from 'lodash/pick';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import Vue from 'vue';
import Vue2TouchEvents from 'vue2-touch-events';
import VueClipboard from 'vue-clipboard2';
import VModal from 'vue-js-modal';
import 'vue-lazy-youtube-video/dist/style.css';
import VueMeta from 'vue-meta';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import panZoom from 'vue-panzoom';
import Router from 'vue-router';
import VueScrollTo from 'vue-scrollto';
import Toasted from 'vue-toasted';
import {mapGetters} from 'vuex';

import consts from '@/base/utils/consts';

import NotificationVerifyEmail from '@/components/common/modals/NotificationVerifyEmail.vue';

import App from './App';
import NotificationsPlugin from './plugins/notifications';
import {stripe} from './plugins/stripe';
import router from './router';
import {RELATED_ROUTES} from './router/consts';
import store from './store';

Vue.use(Router);
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});
Vue.use(VModal, {dynamic: true, injectModalsContainer: true});
Vue.use(FloatingVue, {
  distance: 10,
  arrowPadding: 10,
  themes: {
    tooltip: {
      html: true,
    },
    'interactive-tooltip': {
      $extend: 'tooltip',
      $resetCss: true,
      triggers: [],
      autoHide: false,
    },
    'tooltip-info': {
      $extend: 'tooltip',
      $resetCss: true,
    },
    'tooltip-onboarding': {
      $extend: 'tooltip',
      $resetCss: true,
    },
    'clear-dropdown': {
      $extend: 'dropdown',
      disposeTimeout: 0,
    },
  },
});
Vue.use(VueClipboard);
Vue.use(Toasted, {
  position: 'top-center',
  duration: 3000,
  keepOnHover: true,
  router,
});
Vue.use(panZoom);
Vue.use(VueScrollTo);
Vue.use(breakpoints);
Vue.use(Vue2TouchEvents, {
  disableClick: true,
});

Vue.config.productionTip = false;

Vue.prototype.network = network;
Vue.prototype.$getAsset = getAsset;

if (!window['__PRERENDER_INJECTED'] && !isBotUserAgent()) {
  Vue.use(stripe);
  Vue.use(NotificationsPlugin);

  if (process.env.VUE_APP_GA_ENABLE) {
    let GoogleAnalytics = require('@common/utils/analytics/google_analytics');
    GoogleAnalytics.init();
  }
  // if (process.env.VUE_APP_FACEBOOK_PIXEL_ID) {
  //   AnalyticsFacebookPixelHandler.init();
  // }
  AnalyticsMainHandler.init(process.env.VUE_APP_POSTHOG_ID, true);

  if (process.env.VUE_APP_PROFILE === consts.PROD_PROFILE) {
    initHubspot();
  }

  if (process.env.VUE_APP_HOTJAR_ID) {
    initHotjar();
  }
}

Vue.component('mcr-loading-indicator', LoadingIndicator);

/* eslint-disable no-new */
const root = new Vue({
  router,
  store,
  components: {App},
  directives: {App},
  template: '<App/>',
  destroyed() {
    window.removeEventListener('resize', this.$calculateWidth);
  },
  created() {
    window.addEventListener('resize', this.$calculateWidth);
    window.addEventListener('load', this.$calculateWidth);
    this.$calculateWidth();
    if (this.$store.getters.userEmailState) {
      AnalyticsMainHandler.setUserId(this.$store.getters.userEmailState);
      AnalyticsHubspotHandler.identify(this.$store.getters.userEmailState);
    }
    if (this.userIsLoggedInState) {
      this.$store.dispatch('getNotificationsAction');
    }
  },
  computed: {
    ...mapGetters([
      'userIsLoggedInState',
      'userEmailState',
      'userFirstNameState',
      'userLastNameState',
      'userFullNameState',
    ]),
  },
  methods: {
    $calculateWidth() {
      var scrollDiv = document.createElement('div');
      scrollDiv.className = 'scrollbar-measure';
      scrollDiv.style.width = '100px';
      scrollDiv.style.height = '100px';
      scrollDiv.style.overflow = 'scroll';
      scrollDiv.style.position = 'absolute';
      scrollDiv.style.top = '-9999px';
      document.body.appendChild(scrollDiv);
      var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
      document.body.removeChild(scrollDiv);

      // window.innerWidth works weirdly on ios
      this.$store.commit('setWindowWidthState', document.documentElement.clientWidth + scrollbarWidth);
    },
  },
});

document.addEventListener('DOMContentLoaded', function () {
  root.$mount('#app');
});

router.beforeEach((to, from, next) => {
  if (to.name !== from.name) {
    NProgress.start();
  }
  const previousRoute = pick(from, 'fullPath', 'name', 'meta', 'params', 'path', 'query');
  router.app.$store.commit('setPreviousRouteState', previousRoute);

  router.app.$store.dispatch('hideGalleryAction');
  router.app.$store.commit('setBodyForbidScrollState', false);
  router.app.$modal.hide(consts.MODAL_NAME_QUICK_SIDEBAR);
  // remove this when update vue-js-modal to v2.0 and use $modal.hideAll();
  const modalsContainer = document.getElementById('modals-container');
  while (modalsContainer && modalsContainer.firstChild) {
    modalsContainer.removeChild(modalsContainer.firstChild);
  }
  next();
});

router.beforeResolve((to, from, next) => {
  const userIsLoggedInState = router.app.$store.getters.userIsLoggedInState;
  if (
    (to.name === 'login' || to.name === 'register') &&
    from.name &&
    from.name !== 'login' &&
    from.name !== 'register'
  ) {
    router.app.$store.commit('setFullPathBeforeLoginRegisterState', from.fullPath);
  }
  if (to.meta.authRequired && !userIsLoggedInState) {
    router.app.$store.commit('setFullPathBeforeLoginRegisterState', to.fullPath);
    const nextRoute = to.meta.redirectToLogin ? 'login' : 'register';
    next({name: nextRoute, query: {redirect: to.fullPath}});
    NProgress.done();
    return;
  }
  if (to.meta.verifyRequired && !router.app.$store.getters.userEmailVerifiedState) {
    next({name: 'require-verify', query: {redirect: to.fullPath}});
    NProgress.done();
    return;
  }
  const ftRoutesMapping = {
    'main-page': 'familytree-details',
    'familytree-details-my': 'familytree-details',
    'familytree-lineage-my': 'familytree-lineage',
    'familytree-library-my': 'familytree-library',
  };
  if (ftRoutesMapping[to.name] && userIsLoggedInState && !router.app.$store.getters.userIsSharedState) {
    next({
      name: ftRoutesMapping[to.name],
      params: {id: router.app.$store.getters.activeFamilyTreeIdState},
      query: to.query,
    });
    NProgress.done();
    return;
  }
  /* when user opens /familytree/my redirect to their tree id */
  if (
    Object.values(ftRoutesMapping).includes(to.name) &&
    to.params.id === 'my' &&
    userIsLoggedInState &&
    !router.app.$store.getters.userIsSharedState
  ) {
    next({
      name: to.name,
      params: {id: router.app.$store.getters.activeFamilyTreeIdState},
      query: to.query,
    });
    NProgress.done();
    return;
  }
  const userOnboarded = router.app.$store.getters.userIsOnboardedState;
  const onboardingRoute = !to.params.id || parseInt(to.params.id) === router.app.$store.getters.userFamilyTreeIdState;
  if (userIsLoggedInState && to.meta.forceOnboarding && !userOnboarded && onboardingRoute) {
    const onboardingStep = router.app.$store.getters.userOnboardingStepState;
    const stepName = consts.ONBOARDING_STEPS.includes(onboardingStep) ? onboardingStep : 'familytree-onboarding-start';
    next({name: stepName});
    NProgress.done();
    return;
  }
  next();
});

function forbidStopRequests(fromName, toName) {
  if (fromName === toName) {
    return true;
  }
  for (let routes of RELATED_ROUTES) {
    if (routes.includes(fromName) && routes.includes(toName)) {
      return true;
    }
  }
}

router.afterEach((to, from) => {
  NProgress.done();

  setTimeout(() => {
    // main menu tooltip is misplaced when browser scrollbar appears or disappears between routes
    window.dispatchEvent(new Event('resize'));
  }, 0);

  handleImpersonationOnRouteChange(from, to, router.app);

  if (from.name && !forbidStopRequests(from.name, to.name)) {
    // hack to stop browser from finishing loading of images (and all other requests) on route change
    try {
      window.stop(); // does not work in IE11
    } catch (exception) {
      document.execCommand('Stop');
    }
  }

  if (window.ga) {
    ga('set', 'page', to.path);
    ga('send', 'pageview');
  }

  const toName = getRoutePageName(to);
  const fromName = getRoutePageName(from);
  const toIdentifier = getRoutePageIdentifier(to);
  const fromIdentifier = getRoutePageIdentifier(from);
  if (toName && (fromName !== toName || toIdentifier !== fromIdentifier)) {
    if (
      router.app.$store.getters.userIsLoggedInState &&
      router.app.$store.getters.nextRefreshTimeState <= new Date().getTime()
    ) {
      router.app.$store.dispatch('refreshMyInformationAction').then(response => {
        router.app.$store.commit(
          'setNextRefreshTimeState',
          new Date().getTime() + parseInt(process.env.VUE_APP_REFRESH_TOKEN_TIMEOUT)
        );

        if (
          !response.email_verified &&
          router.app.$store.getters.userIsOnboardedState &&
          router.app.$store.getters.nextVerifyNotificationTimeState <= new Date().getTime()
        ) {
          router.app.$notification.show(NotificationVerifyEmail);
        }
      });
    }
    router.app.$store.dispatch('fetchFeatureSwitchesAction').then(switches => {
      if (!to.meta.customPageOpenTrack) {
        AnalyticsMainHandler.trackPageView(toName, toIdentifier);
      }
    });

    AnalyticsFacebookPixelHandler.tackPageView();
    AnalyticsHubspotHandler.trackPageView(to.path);
  }
});
