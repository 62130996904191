const ASSETS_PER_PAGE = 40;
const ASSETS_META_INIT = {
  // is used to "clean" meta
  limit: ASSETS_PER_PAGE,
  offset: 0,
  total_count: 0,
};

export default {
  libraryFamilyTreeId: null,
  assets: [],
  assetsMetaInit: {...ASSETS_META_INIT},
  assetsMeta: {...ASSETS_META_INIT},
  assetsDisplayMeta: {...ASSETS_META_INIT}, // is modified when file is uploaded to display counters, but not re-request list of assets
  assetsLoading: null,
  assetsOrdering: null,
  assetsFilters: [],

  searchOptions: null,
  searchOptionsLoading: null,

  assetDetailsById: {},
  assetDetailsLoading: null,
  assetUpdateLoading: null,

  assetDeleteLoading: null,
  assetWasDeleted: false,

  assetsDetailsIdsList: [],
  assetsDetailsChunkPageNumber: null,

  profileCropImageLoading: false,
  profileCropImageUrl: null,
};
