export default {
  setClansSearchLoadingState(state, loading) {
    state.clansSearchLoading = loading;
  },
  setClansSearchState(state, clans) {
    state.clansSearch = clans;
  },
  setClansSearchMetaState(state, meta) {
    state.clansSearchMeta = meta;
  },
  setClansSearchStartTimeState(state, time) {
    state.clansSearchStartTime = time;
  },

  setClansListState(state, list) {
    state.clansList = list;
  },
  setClansListMetaState(state, meta) {
    state.clansListMeta = meta;
  },
  setClansListLoadingState(state, loading) {
    state.clansListLoading = loading;
  },

  setClanDetailedState(state, data) {
    state.clanDetailed = data;
  },
  setClanDetailedLoadingState(state, loading) {
    state.clanDetailedLoading = loading;
  },
  setSpellingDetailedState(state, data) {
    state.spellingDetailed = data;
  },
  setSpellingDetailedLoadingState(state, loading) {
    state.spellingDetailedLoading = loading;
  },
  setSpellingSearchState(state, data) {
    state.spellingSearch = data;
  },
  setSpellingSearchMetaState(state, data) {
    state.spellingSearchMeta = data;
  },
  setSpellingSearchLoadingState(state, loading) {
    state.spellingSearchLoading = loading;
  },

  setDialectState(state, data) {
    state.dialect = data;
  },
  setDialectLoadingState(state, loading) {
    state.dialectLoading = loading;
  },
};
