<template>
  <div class="popup-notification">
    <div class="message">
      <div class="notification-icon"><alert-icon :size="40"></alert-icon></div>
      <div>
        <span>{{ text }}</span>
        <a class="action" @click="goToActionUrl">{{ actionText }}</a>
      </div>
    </div>
    <div class="actions">
      <a class="action" @click="close">Close X</a>
    </div>
  </div>
</template>

<script>
import AlertIcon from 'vue-material-design-icons/AlertCircle';
import VueRouter from 'vue-router';

const {isNavigationFailure, NavigationFailureType} = VueRouter;

export default {
  props: {
    identifier: String,
    text: String,
    actionText: String,
    actionUrl: [String, Object],
  },
  methods: {
    goToActionUrl() {
      this.close();
      this.$router.push(this.actionUrl).catch(failure => {
        if (isNavigationFailure(failure, NavigationFailureType.duplicated)) {
          this.$router.go();
        }
      });
    },
    close() {
      this.$store.commit('dismissNotificationInState', this.identifier);
    },
  },
  components: {AlertIcon},
  name: 'PopupNotification',
};
</script>

<style lang="scss" scoped>
.popup-notification {
  padding: 15px 15px;
  display: flex;
  flex-direction: row;
  background: $background-alternate-color;
  color: $title-color;
  align-items: center;
  justify-content: space-between;
  box-shadow: $box-shadow-light;

  .icon-container {
    display: flex;
  }

  .message {
    font-size: 20px;
    line-height: 20px;
    padding-left: 100px;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    font-weight: bold;

    .notification-icon {
      flex-shrink: 0;
      display: flex;
      margin-right: 10px;
    }

    .action {
      margin-left: 5px;
    }
  }

  .actions {
    display: flex;

    .action {
      display: flex;
      flex-shrink: 0;
      font-size: 19px;
      padding: 0 10px 0 10px;
      margin: 0;
      color: $supplemental-text-color;
    }
  }

  @media only screen and (max-width: $main-menu-breakpoint) {
    padding: 10px 20px;

    .message {
      padding-left: 0;
    }

    .action {
      font-size: 1em;
    }
  }
}
</style>
