export default {
  setSavedClanPedigreeListState(state, list) {
    state.list = list;
  },
  setSavedClanPedigreeListMetaState(state, meta) {
    state.listMeta = meta;
  },
  setSavedClanPedigreeListLoadingState(state, loading) {
    state.listLoading = loading;
  },
  setSavedClanPedigreeCreateLoadingState(state, loading) {
    state.createLoading = loading;
  },
  setSavedClanPedigreeDeleteLoadingState(state, loading) {
    state.deleteLoading = loading;
  },
};
