<template>
  <div id="app" :class="classes">
    <browser-not-supported v-if="displayBrowserNotSupported"></browser-not-supported>
    <div class="dev-labels">
      <staging-env-label />
      <div class="impersonation-label">impersonating</div>
    </div>
    <popup-notifications v-if="showNotifications"></popup-notifications>

    <services-main-menu v-if="showServicesMainMenu"></services-main-menu>
    <platform-main-menu v-else-if="showMainMenu"></platform-main-menu>
    <xmas-banner-subscription
      v-if="showBannerSubscription"
      class="top"
      @change-visibility="changeBannerSubscriptionDisplayed"
    ></xmas-banner-subscription>
    <cny-banner-subscription
      v-if="showBannerSubscription"
      class="top"
      @change-visibility="changeBannerSubscriptionDisplayed"
    ></cny-banner-subscription>
    <info-banner
      v-if="displaySearchResultsHintsInfoBanner"
      class="top"
      label="Automate search with RecordFinder™ - Build your tree and receive matches by email!"
      :tutorial-id="searchHintsBannerTutorialIdState"
      @click="searchResultsHintsBannerClick"
      @close="searchResultsHintsBannerClose"
    ></info-banner>
    <router-view :class="pageContentClasses" />
    <mcr-footer v-if="showMcrFooter"></mcr-footer>
    <mcr-wiki-glossary-container v-if="displayGlossary"></mcr-wiki-glossary-container>
  </div>
</template>

<script>
import InfoBanner from '@common/elements/notifications/InfoBanner';
import PopupNotifications from '@common/elements/notifications/PopupNotifications';
import stagingEnvLabel from '@common/elements/stagingEnvLabel';
import {getFamilyTreeRoute} from '@common/utils/utils.routes';
import 'babel-polyfill';
import {mapGetters} from 'vuex';

import {isEdge, isIE} from '@/base/utils/browser.env';

import PlatformMainMenu from '@/base/elements/main-menu/PlatformMainMenu';
import ServicesMainMenu from '@/base/elements/main-menu/ServicesMainMenu';

import mcrFooter from '@/components/common/mcrFooter';
import cnyBannerSubscription from '@/components/common/promotions/cnyBannerSubscription';
import xmasBannerSubscription from '@/components/common/promotions/xmasBannerSubscription';

import BrowserNotSupported from '@/components/browser.not.supported';

const mcrWikiGlossaryContainer = () => import('@common/elements/glossary/mcrWikiGlossaryContainer');

export default {
  name: 'App',
  metaInfo: {
    title: 'Trace Your Ancestry in China',
    titleTemplate: '%s – My China Roots',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          "Discover your family history with the world's leading Chinese genealogy website. Search for zupus, burials, immigration records or hire an expert in China.",
      },
      {
        vmid: 'og:description',
        name: 'og:description',
        content:
          "Discover your family history with the world's leading Chinese genealogy website. Search for zupus, burials, immigration records or hire an expert in China.",
      },
      {
        vmid: 'og:image:secure_url',
        property: 'og:image:secure_url',
        content: 'https://cdn.mychinaroots.com/src/assets/video/mcr-reel.jpg',
      },
      {
        vmid: 'og:image',
        property: 'og:image',
        content: 'https://cdn.mychinaroots.com/src/assets/video/mcr-reel.jpg',
      },
      {
        vmid: 'og:title',
        property: 'og:title',
        content: 'My China Roots – Trace Your Ancestry in China',
      },
      {
        vmid: 'twitter:image',
        name: 'twitter:image',
        content: 'https://cdn.mychinaroots.com/src/assets/video/mcr-reel.jpg',
      },
      {
        vmid: 'og:type',
        property: 'og:type',
        content: 'website',
      },
      {
        vmid: 'image',
        name: 'image',
        content: 'https://cdn.mychinaroots.com/src/assets/video/mcr-reel.jpg',
      },
      //Standard Meta
      {charset: 'utf-8'},
      {name: 'viewport', content: 'width=device-width,initial-scale=1.0'},
    ],
  },
  watch: {
    bodyForbidScrollState(forbidScroll) {
      forbidScroll ? document.body.classList.add('no-scroll') : document.body.classList.remove('no-scroll');
    },
  },
  data() {
    return {
      bannerSubscriptionDisplayed: false,
    };
  },
  computed: {
    ...mapGetters([
      'bodyForbidScrollState',
      'impersonatedUserEmailState',
      'searchHintsBannerDisplayState',
      'searchHintsBannerTutorialIdState',
      'areSearchResultsShownState',
    ]),
    showNotifications() {
      return !this.$route.meta.hideNotifications;
    },
    showMainMenu() {
      return this.$route.name && !this.$route.meta.hideMainMenu;
    },
    showServicesMainMenu() {
      return this.showMainMenu && this.$route.meta.isService;
    },
    showMcrFooter() {
      return this.$route.name && this.$route.meta.showMcrFooter;
    },
    pageContentClasses() {
      let classes = ['page-content'];
      if (this.showMainMenu) {
        classes.push('has-offset-top');
      }
      if (this.displayBrowserNotSupported) {
        classes.push('browser-not-supported-offset');
      }
      if (this.showBannerSubscription && this.bannerSubscriptionDisplayed) {
        classes.push('has-xmas-banner');
      }
      return classes;
    },
    browserNotSupported() {
      return isIE() || isEdge();
    },
    displayBrowserNotSupported() {
      return this.$route.meta.displayBrowserNotSupported && this.browserNotSupported;
    },
    displayGlossary() {
      return this.$route.name && this.$route.meta.displayGlossary;
    },
    isMobile() {
      return this.$store.getters.windowWidthState <= this.$breakpoints.mobile + 1;
    },
    classes() {
      return {sm_md: this.isMobile, 'menu-shown': this.showMainMenu, impersonation: this.impersonatedUserEmailState};
    },
    showBannerSubscription() {
      return this.showMainMenu && !this.$route.meta.isService && !this.$route.meta.hideBannerSubscription;
    },
    displaySearchResultsHintsInfoBanner() {
      return (
        this.searchHintsBannerDisplayState &&
        this.$route.name === 'search-all-records' &&
        this.areSearchResultsShownState
      );
    },
  },
  methods: {
    changeBannerSubscriptionDisplayed(show) {
      this.bannerSubscriptionDisplayed = show;
    },
    searchResultsHintsBannerClick() {
      this.$router.push(getFamilyTreeRoute(this.$store)).catch(() => {});
    },
    searchResultsHintsBannerClose() {
      this.$store.commit('setSearchHintsBannerDisplayState', false);
    },
  },
  components: {
    InfoBanner,
    PopupNotifications,
    BrowserNotSupported,
    mcrWikiGlossaryContainer,
    mcrFooter,
    stagingEnvLabel,
    ServicesMainMenu,
    PlatformMainMenu,
    xmasBannerSubscription,
    cnyBannerSubscription,
  },
};
</script>

<style lang="scss">
@import '@common/style/base';
@import '@common/style/typography';

#app {
  min-height: 100svh;
  display: flex;
  flex-direction: column;

  /* Internet explorer fix for footer */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: block;
  }

  .dev-labels {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 101;
    .impersonation-label {
      display: none;
      background-color: $impersonation-color;
      color: $impersonation-text-color;
      padding: 8px;
      box-shadow: $box-shadow;
    }
  }

  &.impersonation {
    .dev-labels {
      .impersonation-label {
        display: block;
      }
    }
    .main_menu .user-menu .main_menu_link {
      color: $impersonation-color;
      font-weight: bold;
    }
  }

  .xmas-banner-subscription.top,
  .cny-banner-subscription.top,
  .info-banner.top {
    top: $main-menu-height;
    @media only screen and (max-width: $main-menu-breakpoint) {
      top: $main-menu-height-mobile;
    }
  }

  &.sm_md {
    min-width: auto;
  }

  .page-content {
    flex: 1;
  }

  .page-content.has-offset-top {
    padding-top: $main-menu-height;
  }
  .page-content.has-offset-top.browser-not-supported-offset {
    padding-top: calc(#{$browser-not-supported-height} + #{$main-menu-height});
  }

  @media only screen and (max-width: $main-menu-breakpoint) {
    .page-content.has-offset-top {
      padding-top: $main-menu-height-mobile;
    }
    .page-content.has-offset-top.browser-not-supported-offset {
      padding-top: calc(#{$browser-not-supported-height} + #{$main-menu-height-mobile});
    }
  }
  @media only screen and (max-width: $main-menu-minimum-width) {
    .page-content.has-offset-top {
      padding-top: calc(#{$main-menu-height-mobile} + 60px);
    }
    .page-content.has-offset-top.browser-not-supported-offset {
      padding-top: calc(#{$browser-not-supported-height} + #{$main-menu-height-mobile} + 60px);
    }
  }
}
</style>
