import network from '@common/network/network';
import {getClearSourcesSearchFacets} from '@common/utils/utils.sources';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';

export default {
  sourcesTextSearchCountAction(context, filters) {
    context.commit('setSourcesTextSearchCountLoadingState', true);
    return new Promise((resolve, reject) => {
      network.source
        .searchBooksContent(filters)
        .then(response => {
          context.commit('setSourcesTextSearchCountState', response.meta.total_count);
          resolve(response);
        })
        .finally(() => {
          context.commit('setSourcesTextSearchCountLoadingState', false);
        });
    });
  },
  sourcesTextSearchAction(context, filters) {
    const limit = 10;
    const offset = limit * ((filters.page || 1) - 1);
    filters.limit = limit;
    filters.offset = offset;

    const params = pickBy(filters, identity);

    context.commit('setSourcesTextSearchLoadingState', true);
    const meta = context.getters.sourcesTextSearchMetaState;
    if (meta && meta.facets) {
      context.commit('setSourcesTextSearchMetaState', {
        ...meta,
        facets: getClearSourcesSearchFacets(filters, meta.facets),
      });
    }

    return new Promise((resolve, reject) => {
      network.source
        .searchBooksContent(params)
        .then(response => {
          context.commit('setSourcesTextSearchMetaState', response.meta);
          context.commit('setSourcesTextSearchListState', response.objects);
          resolve(response);
        })
        .catch(err => {
          console.error(err);
          reject(err);
        })
        .finally(() => {
          context.commit('setSourcesTextSearchLoadingState', false);
        });
    });
  },
  fetchSourcesTextSearchOptionsAction(context) {
    context.commit('setSourcesTextSearchOptionsLoadingState', true);

    return new Promise((resolve, reject) => {
      network.source
        .getSourcesContentOptions()
        .then(response => {
          context.commit('setSourcesTextSearchOptionsState', response.meta.facets);
          resolve(response);
        })
        .catch(err => {
          console.error(err);
          reject(err);
        })
        .finally(() => {
          context.commit('setSourcesTextSearchOptionsLoadingState', false);
        });
    });
  },
};
