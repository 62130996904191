export default {
  clanPedigreesMetaListState(context) {
    return context.clanPedigreesMetaList;
  },

  clanPedigreeDetailsState(context) {
    return context.clanPedigreeDetails;
  },
  clanPedigreeDetailsLoadingState(context) {
    return context.clanPedigreeDetailsLoading;
  },
};
