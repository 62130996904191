export default {
  setPressArticlesIsLoadingState(state, isLoading) {
    state.pressArticlesIsLoading = isLoading;
  },
  modifyPressArticleFilterState(state, {filterName, filterValue}) {
    if (filterValue) {
      state.pressArticlesActiveFilters = {...state.pressArticlesActiveFilters, [filterName]: filterValue};
    } else {
      delete state.pressArticlesActiveFilters[filterName];
      state.pressArticlesActiveFilters = {...state.pressArticlesActiveFilters};
    }
  },
  removePressArticleFilterState(state, filterName) {
    delete state.pressArticlesActiveFilters[filterName];
    state.pressArticlesActiveFilters = {...state.pressArticlesActiveFilters};
  },
  setPressArticlesState(state, pressArticles) {
    state.pressArticlesList = pressArticles;
    let categoryFilters = {};
    let languageFilters = {};
    for (let article of state.pressArticlesList) {
      languageFilters[article.language] = {name: article.language, value: article.language};
      categoryFilters[article.category] = {name: article.category, value: article.category};
    }

    state.pressArticlesLanguageFilters = Object.values(languageFilters);
    state.pressArticlesCategoryFilters = Object.values(categoryFilters);
  },
};
