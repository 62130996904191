import network from '@common/network/network';

export default {
  fetchOnboardingDataAction(context) {
    context.commit('setTreeOnboardingDataLoadingState', true);
    return new Promise((resolve, reject) => {
      network.familyTree
        .getTreeOnboardingData()
        .then(response => {
          context.commit('setTreeOnboardingDataState', response);
        })
        .catch(reject)
        .finally(() => {
          context.commit('setTreeOnboardingDataLoadingState', false);
        });
    });
  },
  updateOnboardingDataAction(context, {isOnboarded, onboardingStep}) {
    context.commit('setTreeOnboardingUpdateLoadingState', true);
    return new Promise((resolve, reject) => {
      const data = {
        is_onboarded: isOnboarded,
        onboarding_step: onboardingStep,
      };
      network.familyTree
        .updateTreeOnboardingData(data)
        .then(response => {
          context.commit('advanceUserOnboardingState', {isOnboarded, onboardingStep});
          resolve(response);
        })
        .catch(reject)
        .finally(() => {
          context.commit('setTreeOnboardingUpdateLoadingState', false);
        });
    });
  },
};
