<template>
  <div class="hints-paywall-modal-content">
    <div class="close-btn round" @click="closeModal"></div>
    <div class="image-container">
      <img :src="$getAsset('/assets/services/hints-paywall-cover.png')" width="870" height="400" />
    </div>
    <div class="paywall-modal-content">
      <div class="left-side">
        <h4>Upgrade your plan to discover matching records!</h4>
        <div class="text-md">With RecordFinder™, it's easier than ever to trace your Chinese ancestors.</div>
        <div class="text-md">
          Just fill out your family tree, and get notified of matching records in the My China Roots Database!
        </div>
        <a @click="goToSubscriptionPlans">Check out subscription plans</a>
      </div>
      <div class="right-side">
        <h4>Upgrade your plan to discover matching records!</h4>
        <div class="item text-lg text-lg-mobile checked">Search Overseas Collections</div>
        <div class="item text-lg text-lg-mobile checked">Search China Collections</div>
        <div class="item text-lg text-lg-mobile checked">RecordFinder™</div>
        <div class="item text-lg text-lg-mobile checked">Text Extraction & Translation</div>
        <mcr-loading-indicator v-if="plansLoading" :loading="true"></mcr-loading-indicator>
        <template v-else>
          <mcr-button :loading="stripeLoading" @click="startSubscription">{{ buttonLabel }}</mcr-button>
          <div class="text-sm">{{ buttonSubText }}</div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import {getSubscriptionWallRoute} from '@common/utils/utils.routes';
import {mapGetters} from 'vuex';

export default {
  data() {
    return {
      modalName: 'hints-paywall-modal',
      stripeLoading: false,
    };
  },
  created() {
    this.$stripe.init();
    AnalyticsMainHandler.trackPaywallModalOpenAction('hints');
    if (!this.$store.getters.subscriptionPlansState.length) {
      this.$store.dispatch('fetchSubscriptionPlansAction');
      this.$store.dispatch('fetchSubscriptionsAction');
    }
  },
  computed: {
    ...mapGetters(['windowWidthState', 'standardYearlyPlanState', 'isTrialEligibleState']),
    subscriptionPlansRoute() {
      return getSubscriptionWallRoute(this.$route.fullPath, null, true);
    },
    buttonLabel() {
      return this.isTrialEligibleState ? `Start Free ${this.standardTrialDays} Day Trial` : 'Become a Member';
    },
    buttonSubText() {
      const cost = this.standardYearlyPlanState ? this.standardYearlyPlanState.cost : '';
      const costText = `$${cost}/year`;
      const trialText = this.isTrialEligibleState ? ` after ${this.standardTrialDays} day trial` : '';
      return costText + trialText;
    },
    standardTrialDays() {
      return this.standardYearlyPlanState ? this.standardYearlyPlanState.trial_days : 0;
    },
    plansLoading() {
      return this.$store.getters.subscriptionPlansLoadingState || this.$store.getters.subscriptionsLoadingState;
    },
  },
  methods: {
    startSubscription() {
      this.stripeLoading = true;
      const cancelUrl = this.$router.resolve({name: this.$route.name, query: this.$route.query}).href;
      this.$store
        .dispatch('createSubscriptionCheckoutSessionAction', {
          plan_id: this.standardYearlyPlanState.id,
          cancel_url: cancelUrl,
        })
        .then(res => {
          if (res.session_id) {
            this.$stripe.redirectToCheckout({sessionId: res.session_id});
          }
        })
        .catch(error => {
          this.stripeLoading = false;
          if (error && error.response && error.response.data) {
            this.$toasted.error(error && error.response && error.response.data);
          }
        });
    },
    goToSubscriptionPlans() {
      this.closeModal();
      this.$router.push(this.subscriptionPlansRoute);
    },
    closeModal() {
      this.$emit('close');
    },
  },
  components: {McrButton},
  name: 'HintsPaywallModal',
};
</script>

<style lang="scss" scoped>
.hints-paywall-modal-content {
  position: relative;
  max-height: 99vh;

  .image-container {
    display: flex;
    height: calc(100% - 330px);
    overflow: hidden;

    img {
      object-fit: cover;
      width: 100%;
    }
  }
  .paywall-modal-content {
    padding: 32px;
    display: flex;
    flex-direction: row;
    column-gap: 64px;
    > * {
      width: 100%;
    }

    .left-side {
      .text-md {
        margin: 12px 0;
        color: $neutral-600;
      }
      .text-md + .text-md {
        margin-top: 24px;
      }
    }

    .right-side {
      h4 {
        display: none;
      }
      .item.checked::before {
        content: '✓';
        display: inline-block;
        margin-right: 5px;
      }
      .item + .item {
        margin-top: 16px;
      }

      .icon {
        margin-right: 5px;
      }
    }

    .mcr-button {
      margin-top: 32px;
    }

    .text-sm {
      color: $neutral-600;
      margin-top: 10px;
      text-align: center;
    }
  }
  .mcr-loading-indicator {
    margin: 20px 0 0 0;
  }

  @media only screen and (max-width: $breakpoint-phablet) {
    .image-container {
      height: calc(100% - 375px);
    }
    .paywall-modal-content {
      padding: 16px;

      .left-side {
        display: none;
      }
      .right-side {
        h4 {
          display: block;
          margin-bottom: 26px;
        }
      }
    }
  }

  @media only screen and (max-width: 930px), screen and (max-height: 840px) {
    .close-btn.round {
      font-size: 28px;
      right: 8px;
      top: 8px;
      width: 40px;
      height: 40px;
    }
  }
}
</style>
