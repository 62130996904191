import {disableTracking} from './utils.analytics';

export default class AnalyticsFacebookPixelHandler {
  static init() {
    if (disableTracking()) {
      return;
    }
    (function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', process.env.VUE_APP_FACEBOOK_PIXEL_ID);
    this.tackPageView();
  }

  static trackContactEvent() {
    return this._trackEvent('Contact');
  }

  static trackSearchEvent({contentCategory}) {
    return this._trackEvent('Search', {
      content_category: contentCategory,
    });
  }

  static tackPageView() {
    return this._trackEvent('PageView');
  }

  static _trackEvent(name, params = {}) {
    if (window.fbq) {
      fbq('track', name, params);
    }
  }
}
