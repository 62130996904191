export default {
  clanPedigreesSearchListState(context) {
    return context.list || [];
  },
  clanPedigreesSearchMetaState(context) {
    return context.meta || {};
  },
  clanPedigreesSearchLoadingState(context) {
    return context.loading;
  },

  clanPedigreesSearchFormClanState(context) {
    return context.clan;
  },
  clanPedigreesSearchFormPlaceState(context) {
    return context.place;
  },
  searchClanVariantState(state) {
    return state.searchClanVariant;
  },
};
