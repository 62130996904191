const MY_FT_LIBRARY_ROUTE_NAME = 'familytree-library-my';
const FT_LIBRARY_ROUTE_NAME = 'familytree-library';
const FT_LIBRARY_UPLOAD_ROUTE_NAME = 'familytree-library-upload';
const FT_LIBRARY_ASSET_DETAILS_ROUTE_NAME = 'familytree-library-asset-details';

module.exports = {
  MY_FT_LIBRARY_ROUTE_NAME: MY_FT_LIBRARY_ROUTE_NAME,
  FT_LIBRARY_ASSET_DETAILS_ROUTE_NAME: FT_LIBRARY_ASSET_DETAILS_ROUTE_NAME,
  FT_LIBRARY_ROUTE_NAME: FT_LIBRARY_ROUTE_NAME,
  FT_LIBRARY_UPLOAD_ROUTE_NAME: FT_LIBRARY_UPLOAD_ROUTE_NAME,
  RELATED_ROUTES: [
    [FT_LIBRARY_ROUTE_NAME, FT_LIBRARY_ASSET_DETAILS_ROUTE_NAME],
    ['familytree-details-my', 'familytree-lineage-my'],
    ['familytree-details', 'familytree-lineage'],
    ['familytree-details', 'familytree-profile-details'],
    ['familytree-details', 'familytree-manage-customize'], // map is requested on setting change
    ['familytree-lineage', 'familytree-profile-details'],
    ['search-tool-surname', 'search-tool-surname-detail'],
  ],
};
