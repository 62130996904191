export default {
  clearFamilyTreeLibraryState(state) {
    state.assets = [];
    state.assetsMeta = {...state.assetsMetaInit};
    state.assetsDisplayMeta = {...state.assetsMetaInit};
    state.assetsFilters = [];
  },
  setLibraryFamilyTreeIdState(state, familyTreeId) {
    state.libraryFamilyTreeId = familyTreeId;
  },
  setFamilyTreeLibraryAssetsState(state, assets) {
    state.assets = assets;
  },
  setFamilyTreeLibraryAssetsLoadingState(state, loading) {
    state.assetsLoading = loading;
  },
  setFamilyTreeLibraryAssetsMetaState(state, meta) {
    state.assetsMeta = meta;
    state.assetsDisplayMeta = meta;
  },
  setFamilyTreeAssetsOrderingState(state, ordering) {
    state.assetsOrdering = ordering;
  },
  addToFamilyTreeLibraryAssetsState(state, newFile) {
    state.assets.splice(0, 0, newFile);
  },
  removeFromFamilyTreeLibraryAssetsState(state, assetId) {
    const index = state.assets.findIndex(a => a.object_id.toString() === assetId.toString());
    if (index >= 0) {
      state.assets.splice(index, 1);
      state.assetsDisplayMeta = {
        ...state.assetsDisplayMeta,
        total_count: state.assetsDisplayMeta.total_count - 1,
        limit: state.assetsDisplayMeta.limit - 1,
      };
      state.assetsMeta = {
        ...state.assetsMeta,
        total_count: state.assetsMeta.total_count - 1,
      };
    }
  },
  mutateFamilyTreeLibraryAssetState(state, newFileData) {
    let assets = [...state.assets];
    for (let i = 0; i < assets.length; i++) {
      if (assets[i].object_id === newFileData.object_id) {
        assets[i] = {...assets[i], ...newFileData};
        break;
      }
    }
    state.assets = assets;
  },
  onUploadFamilyTreeLibraryAssetState(state, newFileData) {
    let assets = [...state.assets];
    for (let i = 0; i < assets.length; i++) {
      if (assets[i]._id === newFileData._id) {
        newFileData._id = newFileData.object_id;
        assets[i] = newFileData;
        break;
      }
    }
    state.assets = assets;
    state.assetsDisplayMeta = {
      ...state.assetsDisplayMeta,
      total_count: state.assetsDisplayMeta.total_count + 1,
      limit: state.assetsDisplayMeta.limit + 1,
    };
    state.assetsMeta = {
      ...state.assetsMeta,
      total_count: state.assetsMeta.total_count + 1,
    };
  },
  setFamilyTreeLibrarySearchOptionsState(state, searchOptions) {
    state.searchOptions = searchOptions;
  },
  setFamilyTreeLibrarySearchOptionsLoadingState(state, loading) {
    state.searchOptionsLoading = loading;
  },
  addToFamilyTreeLibraryAssetsFilterState(state, {filterType, filterValue, displayName}) {
    state.assetsFilters.push({filterType, filterValue, displayName});
  },
  removeFamilyTreeLibraryAssetsFilterState(state, {filterType, filterValue}) {
    const index = state.assetsFilters.findIndex(
      element => element.filterType === filterType && element.filterValue === filterValue
    );
    state.assetsFilters.splice(index, 1);
  },
  setFamilyTreeLibraryAssetsFiltersState(state, filters) {
    state.assetsFilters = filters;
  },
  clearFamilyTreeLibraryAssetsFiltersState(state) {
    state.assetsFilters = [];
  },
  setFamilyTreeAssetDetailsLoadingState(state, loading) {
    state.assetDetailsLoading = loading;
  },
  setFamilyTreeAssetUpdateLoadingState(state, loading) {
    state.assetUpdateLoading = loading;
  },
  setFamilyTreeAssetDeleteLoadingState(state, loading) {
    state.assetDeleteLoading = loading;
  },
  mutateFamilyTreeAssetDetailsByIdState(state, asset) {
    const oldAssetData = state.assetDetailsById[asset.object_id] || {};
    state.assetDetailsById = {...state.assetDetailsById, [asset.object_id]: {...oldAssetData, ...asset}};
  },
  setProfileCropImageLoading(state, loading) {
    state.profileCropImageLoading = loading;
  },
  mutateProfileCropImageUrl(state, asset) {
    state.profileCropImageUrl = asset.url;
  },
  setAssetsDetailsIdsListState(state, list) {
    state.assetsDetailsIdsList = list;
  },
  setAssetsDetailsChunkPageNumberState(state, page) {
    state.assetsDetailsChunkPageNumber = page;
  },
  setAssetWasDeletedState(state, bool) {
    state.assetWasDeleted = bool;
  },
};
