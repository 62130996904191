export default {
  libraryFamilyTreeIdState(state) {
    return state.libraryFamilyTreeId;
  },
  familyTreeLibraryAssetsState(state) {
    return state.assets;
  },
  familyTreeLibraryAssetsLoadingState(state) {
    return state.assetsLoading;
  },
  familyTreeLibraryAssetsMetaState(state) {
    return state.assetsMeta;
  },
  familyTreeLibraryAssetsDisplayMetaState(state) {
    return state.assetsDisplayMeta;
  },
  familyTreeAssetsOrderingState(state) {
    return state.assetsOrdering || '-updated_at';
  },
  familyTreeLibraryActiveFiltersState(state) {
    return state.assetsFilters;
  },
  familyTreeLibraryFiltersGroupedState(state) {
    const paramsObject = {};
    for (let filter of state.assetsFilters) {
      paramsObject[filter.filterType] = paramsObject[filter.filterType] || [];
      paramsObject[filter.filterType].push(filter.filterValue);
    }
    return paramsObject;
  },
  familyTreeLibraryFiltersAsStringsState(state) {
    const paramsObject = {};
    for (let filter of state.assetsFilters) {
      paramsObject[filter.filterType] = paramsObject[filter.filterType] || [];
      paramsObject[filter.filterType].push(filter.filterValue);
    }
    for (let [key, value] of Object.entries(paramsObject)) {
      paramsObject[key] = value.map(str => encodeURIComponent(str)).join(',');
    }
    return paramsObject;
  },
  familyTreeLibrarySearchOptionsState(state) {
    return state.searchOptions || [];
  },
  familyTreeLibrarySearchOptionsLoadingState(state) {
    return state.searchOptionsLoading;
  },
  familyTreeAssetDetailsLoadingState(state) {
    return state.assetDetailsLoading;
  },
  familyTreeAssetUpdateLoadingState(state) {
    return state.assetUpdateLoading;
  },
  familyTreeAssetDeleteLoadingState(state) {
    return state.assetDeleteLoading;
  },
  familyTreeAssetDetailsByIdState(state) {
    return state.assetDetailsById;
  },
  assetsDetailsIdsListState(state) {
    return state.assetsDetailsIdsList;
  },
  assetsDetailsChunkPageNumberState(state) {
    return state.assetsDetailsChunkPageNumber;
  },
  assetWasDeletedState(state) {
    return state.assetWasDeleted;
  },
  profileCropImageLoadingState(state) {
    return state.profileCropImageLoading;
  },
  profileCropImageUrlState(state) {
    return state.profileCropImageUrl;
  },
};
