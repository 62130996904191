export default {
  setParenthoodCreateLoadingState(state, loading) {
    state.parenthoodCreateLoading = loading;
  },
  setParenthoodCreateErrorsState(state, errors) {
    state.parenthoodCreateErrors = errors;
  },
  setCoupleUpdateLoadingState(state, loading) {
    state.coupleUpdateLoading = loading;
  },
  setCoupleCreateLoadingState(state, loading) {
    state.coupleCreateLoading = loading;
  },
  setCoupleCreateErrorsState(state, errors) {
    state.coupleCreateErrors = errors;
  },
};
