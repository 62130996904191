export default {
  setSurnameClansSearchListState(context, data) {
    context.clansList = data;
  },
  setSurnameClansSearchLoadingState(context, loading) {
    context.clansLoading = loading;
  },

  setSurnamesListState(context, data) {
    context.surnamesList = data;
  },
  setSurnamesMetaState(context, data) {
    context.surnamesMeta = data;
  },
  setSurnamesLoadingState(context, loading) {
    context.surnamesLoading = loading;
  },
  clearSurnamesSearchState(context) {
    context.surnamesList = null;
    context.surnamesMeta = {};
  },

  setSurnamesSearchFormTextStartState(context, text) {
    context.searchTextStart = text;
  },
  setSurnamesSearchFormTextState(context, text) {
    context.searchText = text;
  },
  setSurnameExistsLoadingState(context, loading) {
    context.surnameExistsLoading = loading;
  },
  setSurnameExistsState(context, exists) {
    context.surnameExists = exists;
  },
};
