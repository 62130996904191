let utls = require('@common/utils/analytics/utils.analytics');

module.exports = {
  init() {
    if (utls.disableTracking()) {
      return;
    }
    window.ga =
      window.ga ||
      function () {
        (ga.q = ga.q || []).push(arguments);
      };
    ga.l = +new Date();
    ga('create', 'UA-121792866-2', 'auto');
    ga('send', 'pageview');
  },
};
