export default {
  setFamilyTreeLineageState(state, lineageList) {
    state.lineage = lineageList;
  },
  setFamilyTreeLineageLoadingState(state, loading) {
    state.lineageLoading = loading;
  },
  mutatePersonFromFamilyTreeLineageState(state, data) {
    const index = state.lineage ? state.lineage.findIndex(p => p.object_id === data.object_id) : -1;
    if (index >= 0) {
      const person = {...state.lineage[index], ...data};
      state.lineage.splice(index, 1, person);
    }
  },
  setFamilyTreeLineageStartPersonIdState(state, id) {
    state.startPersonId = id;
  },
};
