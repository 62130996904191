import {PR_ENTRY_POINT_URL_NAME} from './constants';

export default {
  mutateContactUsFormState(state, data) {
    for (let key of Object.keys(data)) {
      state[key] = data[key];
    }
  },
  cleanupContactUsFormState(state) {
    state.firstName = '';
    state.lastName = '';
    state.email = null;
    state.interests = [];
    state.otherComment = '';
    state.attachments = [];
    state.subscribeToNewsletter = false;
    state.requestInfo = {};
    state.ancestor = {
      relation: null,
      firstName: null,
      lastName: null,
    };
    state.id = null;
  },
  mutateContactUsFormLoadingState(state, loading) {
    state.loading = loading;
  },
  mutateContactUsFormLoadingProgressState(state, loadingProgress) {
    state.loadingProgress = loadingProgress;
  },
  saveProspectRequestEntryPointUrl(context, entryPointUrl) {
    localStorage.setItem(PR_ENTRY_POINT_URL_NAME, entryPointUrl);
  },
  setContactUsFormIdState(state, id) {
    state.id = id;
  },
  mutateContactUsFormAncestorState(state, data) {
    state.ancestor = {...state.ancestor, ...data};
  },
  mutateContactUsFormAdditionalInfoState(state, data) {
    state.additionalInfo = {...state.additionalInfo, ...data};
  },
  setContactUsFormInitDataState(state, data) {
    state.initData = data;
  },
};
