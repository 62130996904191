export default {
  person: null,
  loading: null,
  photosByPersonId: {},
  personPhotosLoading: null,
  personFiles: null,
  personFilesMeta: null,
  personFilesLoading: null,
  profilePictureLoading: null,
  deleteAssetLoading: null,
  relativesByPersonId: {},
  relativesLoading: null,
  options: {},
  optionsLoading: null,
  updateLoading: null,
  factsUpdateLoading: null,
  updateErrors: null,
  deleteLoading: null,
  deleteErrors: null,
  createLoading: null,
  createErrors: null,
  mySurnames: [],
  mySurnamesLoading: false,
  acceptDataLoading: false,
};
