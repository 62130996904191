export default {
  setSubscriptionPlansState(state, data) {
    state.plans = data;
  },
  setSubscriptionPlansLoadingState(state, data) {
    state.plansLoading = data;
  },
  setSubscriptionsState(state, data) {
    state.subscriptions = data;
  },
  mutateSubscriptionsState(state, {subId, newData}) {
    state.subscriptions = state.subscriptions.map(sub => {
      return sub.id === subId ? {...sub, ...newData} : sub;
    });
  },
  setSubscriptionsLoadingState(state, data) {
    state.subscriptionsLoading = data;
  },
  setSubscriberLinkState(state, data) {
    state.subscriberLink = data;
  },
};
