import network from '@common/network/network';

export default {
  searchClanPedigreesAction(context, {clan_id, clan_name, place_id, page, only_meta}) {
    page = page || 1;
    const limit = 10;
    const offset = limit * (page - 1);
    let params = {offset, limit, clan_id, clan_name, place_id, only_meta};

    context.commit('setClanPedigreesSearchLoadingState', true);
    return new Promise((resolve, reject) => {
      network.pedigree
        .searchPedigree(params)
        .then(response => {
          context.commit('setClanPedigreesSearchMetaState', response.meta);
          context.commit('setClanPedigreesSearchListState', response.objects);
          resolve(response);
        })
        .catch(err => {
          console.error(err);
          context.commit('setClanPedigreesSearchMetaState', {});
          context.commit('setClanPedigreesSearchListState', []);
          reject(err);
        })
        .finally(() => {
          context.commit('setClanPedigreesSearchLoadingState', false);
        });
    });
  },
};
