export default {
  hideGalleryAction(context) {
    context.commit('setGalleryCurrentIndexState', null);
  },
  displayGalleryAction(context, photoId) {
    const images = context.getters.galleryItemsState;
    for (let i = 0; i < images.length; i++) {
      if (images[i]._id === photoId) {
        return context.commit('setGalleryCurrentIndexState', i);
      }
    }
  },
};
