import {NetworkAPIVersion, NetworkParamType} from './network.utils';

export default {
  uploadFile: {
    url: 'familytree/{id}/library/upload/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    multipart: true,
    params: {
      attachment: [NetworkParamType.file],
      tags_data: [NetworkParamType.string],
      source: [NetworkParamType.string],
    },
  },
  uploadFilePart: {
    url: 'familytree/{id}/library/upload-part/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    multipart: true,
    params: {
      file_part: [NetworkParamType.file],
      file_name: [NetworkParamType.string],
      part_number: [NetworkParamType.number],
      is_final: [NetworkParamType.boolean],
      upload_id: [NetworkParamType.string],
      asset_id: [NetworkParamType.string],
      file_key: [NetworkParamType.string],
      parts_info: [NetworkParamType.string],
      total_size: [NetworkParamType.number],
    },
  },
  getAssets: {
    url: 'familytree/{id}/library/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
    autoCancel: true,
    params: {
      limit: [NetworkParamType.number],
      offset: [NetworkParamType.number],
      location__in: [NetworkParamType.string],
      search: [NetworkParamType.string],
      persons: [NetworkParamType.string],
      location__isnull: [NetworkParamType.number],
      persons__isnull: [NetworkParamType.number],
      // ordering
      // - created_at
      // - filename_first_char
      // - event_datetime
      order_by: [NetworkParamType.array],
    },
  },
  getLibrarySearchOptions: {
    url: 'familytree/{id}/library/search-options/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
    params: {
      q: [NetworkParamType.string],
      persons: [NetworkParamType.string],
      locations: [NetworkParamType.string],
    },
  },
  deleteAsset: {
    url: 'asset/{id}/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'delete',
  },
  getAssetDetails: {
    url: 'asset/{id}/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
  },
  assetRotate: {
    url: 'asset/{id}/rotate/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    params: {
      angle: [NetworkParamType.string],
    },
  },
  getAssetUrl: {
    url: 'asset/{id}/get-url/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
  },
  patchAsset: {
    url: 'asset/{id}/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'patch',
    params: {
      event_date: [NetworkParamType.string],
      location_tag: [NetworkParamType.object],
      description: [NetworkParamType.string],
      persons: [NetworkParamType.array],
      filename: [NetworkParamType.string],
    },
  },
};
