import network from '@common/network/network';
import {preload} from '@common/utils/utils.sources';
import pick from 'lodash/pick';
import moment from 'moment';

export default {
  getFamilyTreeLibraryFilteredAssetsChunkAction(context, {page}) {
    const params = {
      limit: context.state.assetsMeta.limit,
      offset: context.state.assetsMeta.limit * (page - 1),
      id: context.getters.libraryFamilyTreeIdState,
      order_by: context.getters.familyTreeAssetsOrderingState,
      ...context.getters.familyTreeLibraryFiltersAsStringsState,
    };
    return network.familyTreeLibrary.getAssets(params);
  },
  preloadNextFamilyTreeLibrarySrcsAction(context, {displayAssetId}) {
    const assets = context.getters.familyTreeLibraryAssetsState;
    const displayIndex = assets ? assets.findIndex(el => el.object_id === displayAssetId) : -1;
    if (displayIndex > -1 && assets.length > displayIndex + 1) {
      const srcList = [assets[displayIndex + 1].attachment];
      preload(srcList);
    }
  },
  fetchFamilyTreeLibraryAssetsAction(context, {familyTreeId, page, orderBy, ...filters}) {
    const limit = context.state.assetsMeta.limit;
    const offset = limit * (page - 1);

    context.commit('setFamilyTreeLibraryAssetsLoadingState', true);
    return new Promise((resolve, reject) => {
      network.familyTreeLibrary
        .getAssets({id: familyTreeId, order_by: orderBy, limit, offset, ...filters})
        .then(response => {
          context.commit('setFamilyTreeLibraryAssetsState', response.objects);
          context.commit('setFamilyTreeLibraryAssetsMetaState', response.meta);
          if (response.meta.family_tree) {
            context.commit('setActiveFamilyTreeIdState', response.meta.family_tree.id);
          }
          resolve();
        })
        .catch(error => {
          if (parseInt(context.getters.activeFamilyTreeIdState) === parseInt(familyTreeId)) {
            context.commit('clearActiveFamilyTreeIdState');
          }
          reject(error);
        })
        .finally(() => {
          context.commit('setFamilyTreeLibraryAssetsLoadingState', false);
        });
    });
  },
  uploadFamilyTreeLibraryAssetAction(context, {familyTreeId, file, tagsData}) {
    const id = moment().unix() + '__' + file.lastModified + '__' + file.name;
    const fileData = {
      _id: id,
      progress: '0',
      error: false,
      uploading: true,
      attachment: '',
      filename: file.name,
      uploadingPreviewSrc: /image/g.test(file.type) ? URL.createObjectURL(new Blob([file])) : '',
    };
    context.commit('addToFamilyTreeLibraryAssetsState', fileData);

    return new Promise((resolve, reject) => {
      network.familyTreeLibrary
        .uploadFile({id: familyTreeId, attachment: file, tags_data: JSON.stringify(tagsData)}, progressRes => {
          fileData.progress = ((progressRes.loaded / progressRes.total) * 100).toFixed(2);
        })
        .then(response => {
          context.commit('onUploadFamilyTreeLibraryAssetState', {_id: id, ...response});
          resolve(response);
        })
        .catch(error => {
          fileData.error = true;
          reject(error);
        });
    });
  },
  getFamilyTreeLibrarySearchOptionsAction(context, {familyTreeId, q, persons, locations}) {
    context.commit('setFamilyTreeLibrarySearchOptionsLoadingState', true);
    return new Promise((resolve, reject) => {
      network.familyTreeLibrary
        .getLibrarySearchOptions({id: familyTreeId, q, persons, locations})
        .then(response => {
          context.commit('setFamilyTreeLibrarySearchOptionsState', response);
          resolve();
        })
        .finally(() => {
          context.commit('setFamilyTreeLibrarySearchOptionsLoadingState', false);
        });
    });
  },
  getAssetDetailsAction(context, {assetId}) {
    context.commit('setFamilyTreeAssetDetailsLoadingState', true);
    return new Promise((resolve, reject) => {
      network.familyTreeLibrary
        .getAssetDetails({id: assetId})
        .then(response => {
          context.commit('mutateFamilyTreeAssetDetailsByIdState', response);
          resolve(response);
        })
        .finally(() => {
          context.commit('setFamilyTreeAssetDetailsLoadingState', false);
        });
    });
  },
  assetRotateAction(context, {assetId, angle}) {
    return network.familyTreeLibrary.assetRotate({id: assetId, angle});
  },
  getProfileCropImageUrl(context, {assetId}) {
    context.commit('setProfileCropImageLoading', true);
    return new Promise((resolve, reject) => {
      network.familyTreeLibrary
        .getAssetUrl({id: assetId})
        .then(response => {
          context.commit('mutateProfileCropImageUrl', response);
          resolve(response);
        })
        .finally(() => {
          context.commit('setProfileCropImageLoading', false);
        });
    });
  },
  patchAssetDetailsAction(context, {id, ...params}) {
    context.commit('setFamilyTreeAssetUpdateLoadingState', true);
    const oldParams = pick(context.getters.familyTreeAssetDetailsByIdState, Object.keys(params));
    context.commit('mutateFamilyTreeAssetDetailsByIdState', {object_id: id, ...params});
    return new Promise((resolve, reject) => {
      network.familyTreeLibrary
        .patchAsset({id, ...params})
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          context.commit('mutateFamilyTreeAssetDetailsByIdState', {object_id: id, ...oldParams});
        })
        .finally(() => {
          context.commit('setFamilyTreeAssetUpdateLoadingState', false);
        });
    });
  },
  setPersonsToAssetDetailsAction(context, {id, persons}) {
    context.commit('setFamilyTreeAssetUpdateLoadingState', true);
    return new Promise((resolve, reject) => {
      network.familyTreeLibrary
        .patchAsset({id, persons})
        .then(response => {
          resolve(response);
        })
        .finally(() => {
          context.commit('setFamilyTreeAssetUpdateLoadingState', false);
        });
    });
  },
  deleteAssetAction(context, assetId) {
    context.commit('setFamilyTreeAssetDeleteLoadingState', true);
    return new Promise((resolve, reject) => {
      network.familyTreeLibrary
        .deleteAsset({id: assetId})
        .then(() => {
          resolve();
        })
        .finally(() => {
          context.commit('setFamilyTreeAssetDeleteLoadingState', false);
        });
    });
  },
};
