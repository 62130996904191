<template>
  <a class="mcr-button" :class="{disabled: disabled}" @click="click" @keyup.enter="click" tabindex="0">
    <div v-if="!loading" class="mcr-button-label">
      <slot>{{ label }}</slot>
    </div>

    <bounce-loading v-if="loading"></bounce-loading>
  </a>
</template>

<script>
import BounceLoading from '@common/elements/loading-indicators/bounceLoading';

export default {
  props: {
    loading: Boolean,
    label: String,
    disabled: {type: Boolean, default: false},
  },
  methods: {
    click() {
      if (!this.disabled && !this.loading) {
        this.$emit('click');
      }
    },
  },
  components: {BounceLoading},
};
</script>

<style lang="scss" scoped>
@import '@common/style/mcr-button';
</style>
