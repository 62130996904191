export default {
  setFamilyTreeHintsListState(state, data) {
    state.list = data;
  },
  setFamilyTreeHintsLoadingState(state, data) {
    state.loading = data;
  },
  setFamilyTreeHintsMetaState(state, data) {
    state.meta = data;
  },

  setFamilyTreeHintDetailsState(state, data) {
    state.details = data;
  },
  setFamilyTreeHintDetailsLoadingState(state, data) {
    state.detailsLoading = data;
  },
  setFamilyTreeHintDetailsPreviousRouteState(context, route) {
    const {fullPath, meta, name, params, path, query} = route;
    context.detailsPreviousRoute = {fullPath, meta, name, params, path, query};
  },
  setFamilyTreeHintIgnoreLoading(context, {id, loading}) {
    context.ignoreLoading = {...context.ignoreLoading, [id]: loading};
  },
  setFamilyTreeHintAcceptLoading(context, {id, loading}) {
    context.acceptLoading = {...context.acceptLoading, [id]: loading};
  },

  setFamilyTreeHintsCountsByPersonIdsState(state, data) {
    state.newByPersonIds = data;
  },
};
