export default {
  setClanPedigreesMetaListState(context, list) {
    context.clanPedigreesMetaList = list;
  },
  setClanPedigreesMetaListLoadingState(context, loading) {
    context.clanPedigreesMetaListLoading = loading;
  },

  setClanPedigreeDetailsState(context, clanPedigree) {
    context.clanPedigreeDetails = clanPedigree;
  },
  mutateClanPedigreeDetailsState(context, data) {
    context.clanPedigreeDetails = {...context.clanPedigreeDetails, ...data};
  },
  setClanPedigreeDetailsLoadingState(context, loading) {
    context.clanPedigreeDetailsLoading = loading;
  },
};
