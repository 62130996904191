export default {
  pressArticlesFilteredState(state) {
    let filteredArticles = state.pressArticlesList;
    for (let filter in state.pressArticlesActiveFilters) {
      filteredArticles = filteredArticles.filter(
        article => article[filter] === state.pressArticlesActiveFilters[filter]
      );
    }
    return filteredArticles;
  },
  pressArticlesLanguageFiltersState(state) {
    return state.pressArticlesLanguageFilters;
  },
  pressArticlesCategoryFiltersState(state) {
    return state.pressArticlesCategoryFilters;
  },
  pressArticlesActiveFiltersState(state) {
    return state.pressArticlesActiveFilters;
  },
  pressArticlesIsLoadingState(state) {
    return state.pressArticlesIsLoading;
  },
};
