<template>
  <div class="main_menu">
    <router-link :to="{name: 'main-page'}" class="mobile-logo">
      <img :src="$getAsset('/assets/mcr_logo_dude_transparent.png')" />
    </router-link>
    <router-link :to="{name: 'main-page'}" class="logo">
      <img :src="$getAsset('/assets/mcr_logo_white_transparent.png')" />
    </router-link>

    <main-menu-item
      v-for="(item, index) of displayMenuItems"
      :key="index"
      :text="item.text"
      :mobile-text="item.mobileText"
      :sub-menu="item.subMenu"
      :route="item.route"
      :is-mobile-view="isMobileView"
      :class="item.classes"
    ></main-menu-item>

    <mcr-button-router-link-to-pr
      :is-button="isContactUsButton"
      label="Contact Us"
      class="contact-us-button"
    ></mcr-button-router-link-to-pr>
  </div>
</template>

<script>
import McrButtonRouterLinkToPr from '@common/elements/buttons/mcrButtonRouterLinkToPr';
import MainMenuItem from '@common/elements/main-menu/main.menu.item';
import {mapGetters} from 'vuex';

export default {
  computed: {
    ...mapGetters(['windowWidthState']),
    displayMenuItems() {
      if (this.isMobileView) {
        return this.menuItems.filter(item => !item.desktopOnly);
      }
      return this.menuItems.filter(item => !item.mobileOnly);
    },
    menuItems() {
      let researchSubMenu = [
        this.getRouterLinkItem('Ancestry', 'village-exploration-service'),
        this.getRouterLinkItem('Adoption', 'adoption'),
        this.getRouterLinkItem('Heirs', 'probate'),
      ];
      let translationSubMenu = [
        this.getRouterLinkItem('Translation', 'translation'),
        this.getRouterLinkItem('Storytelling', 'storytelling'),
        this.getRouterLinkItem('Zupu Content', 'zupu-translation-services'),
      ];
      let travelSubMenu = [
        this.getRouterLinkItem('Roots Trips', 'travel'),
        this.getRouterLinkItem('Legacy Films', 'film'),
      ];
      let aboutSubMenu = [
        this.getRouterLinkItem('Our Story', 'our-story'),
        this.getExternalLinkItem('Blog', '/blog'),
        this.getRouterLinkItem('Events', 'events'),
        this.getRouterLinkItem('Team', 'our-team'),
        this.getRouterLinkItem('Press', 'press'),
        this.getRouterLinkItem('Careers', 'careers'),
        this.getRouterLinkItem('Contact', 'contact'),
      ];

      const testimonials = this.getRouterLinkItem('Testimonials', 'testimonials');
      const about = {text: 'About', subMenu: aboutSubMenu};
      const travel = {text: 'Travel', subMenu: travelSubMenu};
      let additionalMenuItems = this.generateMoreMenuItems([travel, testimonials, about]);

      let menu = this.isMobileSEView ? [] : [this.getRouterLinkItem('Overview', 'services-main')];
      menu = [
        ...menu,
        {text: 'Research', subMenu: researchSubMenu},
        {text: 'Translation', subMenu: translationSubMenu},
        ...additionalMenuItems,
      ];
      return menu;
    },
    isMobileView() {
      return this.windowWidthState <= this.$breakpoints.mainMenu;
    },
    isMobileSEView() {
      return this.windowWidthState <= 450;
    },
    isContactUsButton() {
      return this.windowWidthState >= this.$breakpoints.mainMenu;
    },
  },
  methods: {
    generateMoreMenuItems(items) {
      // returns items as regular menu items for desktop and items as subMenu of `More` on mobile
      let desktopItems = [];
      let moreSubMenu = [];
      moreSubMenu.push({isContactUsButton: true});
      let mobileMore = {text: 'More', subMenu: moreSubMenu, mobileOnly: true};
      for (let item of items) {
        desktopItems.push({...item, desktopOnly: true});
        const {subMenu, ...otherItem} = item;
        if (subMenu) {
          moreSubMenu.push(otherItem);
          moreSubMenu.push(...subMenu);
        } else {
          moreSubMenu.push({text: otherItem.text});
          moreSubMenu.push(otherItem);
        }
      }
      return [...desktopItems, mobileMore];
    },
    getRouterLinkItem(text, routerName, routeParams) {
      return {text: text, route: {name: routerName, params: routeParams || {}}};
    },
    getExternalLinkItem(text, externalLink, icon) {
      return {text, clickHandler: () => window.open(externalLink, '_blank'), icon};
    },
  },
  components: {McrButtonRouterLinkToPr, MainMenuItem},
};
</script>

<style lang="scss" scoped>
@import '@common/style/menu';

.contact-us-button.mcr-button::v-deep .mcr-button-label {
  padding: 5px 8px;
}
</style>
