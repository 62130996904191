import network from '@common/network/network';
import UAParser from 'ua-parser-js';

export default {
  sendContactUsFormAction(
    context,
    {
      email,
      interests,
      otherComment,
      attachments,
      callDatetime,
      subscribeToNewsletter,
      firstName,
      lastName,
      requestInfo,
      formType,
      prEntryPointUrl,
      prEntryPointName,
      isPayment,
      additionalInfo,
    }
  ) {
    context.commit('mutateContactUsFormLoadingState', true);

    let parser = new UAParser();
    let results = parser.getResult();
    requestInfo = {
      ...(requestInfo || {}),
      System: results.os.name + ' ' + results.os.version,
      Browser: results.browser.name + ' ' + results.browser.version,
      Cpu: results.cpu.architecture,
      Device: results.device ? results.device.model + ' ' + results.device.type + ' ' + results.device.vendor : '',
      Engine: results.engine.name + ' ' + results.engine.version,
      Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      FormType: formType,
    };

    return new Promise((resolve, reject) => {
      const data = {
        email: email,
        first_name: firstName || '',
        last_name: lastName || '',
        interests: interests,
        other_comment: otherComment || '',
        entry_point_url: prEntryPointUrl,
        entry_point_name: prEntryPointName,
        is_payment: isPayment,
        attachments: attachments,
        subscribe_to_newsletter: subscribeToNewsletter,
        request_info: JSON.stringify(requestInfo),
        additional_info: JSON.stringify(additionalInfo),
      };
      if (callDatetime) {
        data['call_schedule_datetime'] = callDatetime;
      }
      return network.prospectRequest
        .sendContactUsForm(data, progressRes => {
          context.commit(
            'mutateContactUsFormLoadingProgressState',
            ((progressRes.loaded / progressRes.total) * 100).toFixed(2)
          );
        })
        .then(response => {
          context.commit('setContactUsFormIdState', response.object_id);
          resolve(response);
        })
        .finally(() => {
          context.commit('mutateContactUsFormLoadingState', false);
        });
    });
  },
  updateContactUsFormAction(context, {id, interests, otherComment, attachments}) {
    context.commit('mutateContactUsFormLoadingState', true);
    return new Promise((resolve, reject) => {
      network.prospectRequest
        .updateContactUsForm({
          id,
          interests: interests,
          other_comment: otherComment,
          attachments: attachments,
        })
        .then(response => {
          resolve(response);
        })
        .finally(() => {
          context.commit('mutateContactUsFormLoadingState', false);
        });
    });
  },
  updateOrCreateContactUsFormAction(context, {email, callDatetime}) {
    context.commit('mutateContactUsFormLoadingState', true);

    return new Promise((resolve, reject) => {
      network.prospectRequest
        .updateOrCreateContactUsForm({
          email,
          call_schedule_datetime: callDatetime,
        })
        .then(response => {
          resolve(response);
        })
        .finally(() => {
          context.commit('mutateContactUsFormLoadingState', false);
        });
    });
  },
};
