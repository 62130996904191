export default {
  setUploadImageState(state, data) {
    state.uploadImage = data;
  },
  setImageToTextState(state, data) {
    state.imageData = data;
  },
  setImageToTextLoadingState(state, data) {
    state.imageDataLoading = data;
  },
};
