import getters from './getters';
import mutations from './mutations';
import state from './state';

export default {
  state,
  mutations,
  actions: {},
  getters,
};
