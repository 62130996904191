<template>
  <div class="popup-notifications">
    <transition-group name="notification-slide">
      <popup-notification
        v-for="notif in notifications"
        :identifier="notif.key"
        :key="notif.key"
        :text="notif.content"
        :action-text="notif.action_message"
        :action-url="notif.action_url"
      ></popup-notification>
    </transition-group>
  </div>
</template>

<script>
import PopupNotification from '@common/elements/notifications/PopupNotification';
import {mapGetters} from 'vuex';

export default {
  computed: {
    ...mapGetters(['notificationsState', 'dismissedNotificationsState']),
    notifications() {
      return this.notificationsState.filter(n => !this.dismissedNotificationsState.includes(n.key));
    },
  },
  components: {PopupNotification},
  name: 'PopupNotifications',
};
</script>

<style lang="scss" scoped>
.popup-notifications {
  align-items: center;
  top: $main-menu-height;
  min-width: 100%;
  position: fixed;
  z-index: calc(#{$main-menu-z-index} - 1);

  .popup-notification {
    margin-bottom: 20px;
  }

  @media only screen and (max-width: $main-menu-breakpoint) {
    top: $main-menu-height-mobile;
  }
}

.notification-slide-enter-active,
.notification-slide-leave-active {
  transition: all 0.2s ease;
}
.notification-slide-enter,
.notification-slide-leave-to {
  opacity: 0;
  transform: translateY(50px);
}
</style>
