export default {
  setFamilyTreeDrawLoadingState(state, loading) {
    state.drawLoading = loading;
  },
  setActiveFamilyTreeIdState(state, id) {
    state.activeTreeId = id;
  },
  clearActiveFamilyTreeIdState(state) {
    state.activeTreeId = null;
  },
  setFamilyTreeDetailsIdState(state, id) {
    state.detailsId = id;
  },
  setFamilyTreeNameState(state, name) {
    state.name = name;
  },
  setFamilyTreeHomePerson(state, data) {
    state.homePerson = data;
  },
  setFamilyTreeMapImageHashState(state, mapImageHash) {
    state.mapImageHash = mapImageHash;
  },
  setFamilyTreeRefreshRequestState(state, refreshRequest) {
    state.refreshRequest = refreshRequest;
  },
  setFamilyTreeMapImageState(state, mapImage) {
    state.mapImage = mapImage;
  },
  setFamilyTreeMapImageLoadingState(state, mapImageLoading) {
    state.mapImageLoading = mapImageLoading;
  },
  setFamilyTreeIsMiniMapVisibleState(state, isMiniMapVisible) {
    state.isMiniMapVisible = isMiniMapVisible;
  },
  setFamilyTreeStartPersonIdState(state, id) {
    state.startPersonId = id;
  },
  setFamilyTreeStartPersonYState(state, y) {
    state.startPersonY = y;
  },
  setFamilyTreeFocusPersonIdState(state, id) {
    state.focusPersonId = id;
  },
  setTreeCardMenuShownCardIdState(state, id) {
    state.cardMenuShownCardId = id;
  },
  setFamilyTreePersonsDrawnState(state, persons) {
    state.personsDrawn = persons;
  },
  setFamilyTreeLinesDrawnState(state, lines) {
    state.linesDrawn = lines;
  },
  mutateFamilyTreePersonsByDiff(state, {new_persons, remove_persons, data_update}) {
    remove_persons = remove_persons || [];
    data_update = data_update || {};
    const newPersonIds = new_persons.map(p => p.object_id);
    let personsDrawn = state.personsDrawn.reduce((filtered, person) => {
      if (remove_persons.includes(person.object_id) || newPersonIds.includes(person.object_id)) {
        return filtered;
      }
      if (data_update[person.object_id]) {
        person = {...person, ...data_update[person.object_id]};
      }
      filtered.push(person);
      return filtered;
    }, []);
    state.personsDrawn = [...personsDrawn, ...new_persons];
  },
  mutateFamilyTreeLinesByDiff(state, {new_lines, remove_lines}) {
    remove_lines = remove_lines || [];
    let old_lines = state.linesDrawn.filter(line => !remove_lines.includes(line.id));
    state.linesDrawn = [...old_lines, ...new_lines];
  },
  mutateFamilyTreeLinesDrawnState(state, {relatedPeople, newData}) {
    if (!state.linesDrawn) {
      return;
    }
    state.linesDrawn = state.linesDrawn.map(data => {
      return data.related_people.length === relatedPeople.length &&
        data.related_people.every(id => relatedPeople.includes(id))
        ? {...data, ...newData}
        : data;
    });
  },
  setFamilyTreeDrawnWidthState(state, width) {
    state.treeWidth = width;
  },
  setFamilyTreeDrawnHeightState(state, height) {
    state.treeHeight = height;
  },
  setFamilyTreeDrawnMarginXState(state, marginX) {
    state.treeMarginX = marginX;
  },

  mutateFamilyTreePersonsByIdState(state, {id, ...data}) {
    const index = state.personsDrawn ? state.personsDrawn.findIndex(p => p.object_id === id) : -1;
    if (index >= 0) {
      const person = {...state.personsDrawn[index], ...data};
      state.personsDrawn.splice(index, 1, person);
    }
  },
  setFamilyTreeIsWriteAllowedState(state, isAllowed) {
    state.isWriteAllowed = isAllowed;
  },
  setFamilyTreeIsPublicState(state, isPublic) {
    state.isPublic = isPublic;
  },
  setFamilyTreeMembersState(state, treeMembers) {
    state.treeMembers = treeMembers;
  },
  setFamilyTreeIsUserOwnerState(state, isUserOwner) {
    state.isUserOwner = isUserOwner;
  },
  setFamilyTreePreferencesState(state, preferences) {
    state.preferences = preferences;
  },
  setFamilyTreePreferencesKeyState(state, data) {
    state.preferences = {...state.preferences, ...data};
  },
  setFamilyTreeBloodRelativesIdsState(state, data) {
    state.bloodRelativesIds = data;
  },
  setFamilyTreeIsLangChineseState(state, isLangChinese) {
    state.isLangChinese = isLangChinese;
  },
  setFamilyTreeListState(state, list) {
    state.list = list;
  },
  mutateFamilyTreeListState(state, newItem) {
    state.list = state.list.map(item => (item.object_id == newItem.object_id ? newItem : item));
  },
  mutateFamilyTreeNameInListState(state, {id, name}) {
    if (state.list) {
      state.list = state.list.map(item => (item.object_id == id ? {...item, name} : item));
    }
  },
  setFamilyTreeListLoadingState(state, loading) {
    state.listLoading = loading;
  },
  setFamilyTreeUpdateLoadingByIdState(state, {id, loading}) {
    state.updateLoadingById = {...state.updateLoadingById, [id]: loading};
  },
  setFamilyTreePersonsSearchListState(state, list) {
    state.personsSearchList = list;
  },
  deleteFromFamilyTreePersonsSearchListState(state, personId) {
    if (state.personsSearchList && state.personsSearchList.length) {
      state.personsSearchList = state.personsSearchList.filter(item => item.object_id !== personId);
    }
  },
  setFamilyTreePersonsSearchListLoadingState(state, loading) {
    state.personsSearchListLoading = loading;
  },
  setFamilyTreePersonsSearchListMetaState(state, meta) {
    state.personsSearchListMeta = meta;
  },
  setFamilyTreeMostTaggedLocationsState(state, locations) {
    state.mostTaggedLocations = locations;
  },
  setFamilyTreeMostTaggedLocationsLoadingState(state, loading) {
    state.mostTaggedLocationsLoading = loading;
  },
  setFamilyTreeSurnamesState(state, surnames) {
    state.surnames = surnames;
  },
  setFamilyTreeSurnamesLoadingState(state, loading) {
    state.surnamesLoading = loading;
  },
  setTreeClanMetaState(state, data) {
    state.treeClanMeta = data;
  },

  setFamilyTreeDetailsState(state, details) {
    state.details = details;
  },
  mutateFamilyTreeDetailsState(state, details) {
    const {preferences, ...otherDetails} = details;
    const newPreferences = preferences ? {...state.details.preferences, ...preferences} : state.details.preferences;
    state.details = {...state.details, ...otherDetails, preferences: newPreferences};
  },
  setFamilyTreeDetailsLoadingState(state, loading) {
    state.detailsLoading = loading;
  },
  setFamilyTreeAddMembersLoadingState(state, loading) {
    state.treeAddMembersLoading = loading;
  },
  setFamilyTreeEditMemberLoadingState(state, {email, loading}) {
    state.treeEditMemberLoading = {...state.treeEditMemberLoading, [email]: loading};
  },
  setFamilyTreeDeleteMemberLoadingState(state, {email, loading}) {
    state.treeDeleteMemberLoading = {...state.treeDeleteMemberLoading, [email]: loading};
  },

  initTreeBreadcrumbsState(state, {treeId, homePerson}) {
    state.breadcrumbs = {treeId, homePerson, persons: []};
  },
  handleTreeBreadcrumbsState(state, {person}) {
    if (!state.breadcrumbs) {
      return;
    }
    const personId = person.id;
    if (state.breadcrumbs.homePerson && personId === state.breadcrumbs.homePerson.id) {
      state.breadcrumbs = {...state.breadcrumbs, persons: []};
      return;
    }
    const index = state.breadcrumbs.persons.findIndex(p => p.id === personId);
    if (index > -1) {
      state.breadcrumbs = {...state.breadcrumbs, persons: state.breadcrumbs.persons.slice(0, index + 1)};
    } else {
      state.breadcrumbs = {...state.breadcrumbs, persons: [...state.breadcrumbs.persons, person]};
    }
  },
  setIsExpandControlChangedState(state, bool) {
    state.isExpandControlChanged = bool;
  },

  setCreatePrefillSurnamesState(state, surnames) {
    state.createPrefillSurnames = surnames;
  },

  setAllTreesPhotosState(state, list) {
    state.allPhotos = list;
  },
  addToAllTreesPhotosState(state, list) {
    state.allPhotos = [...state.allPhotos, ...list];
  },
};
