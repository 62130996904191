import {NetworkAPIVersion, NetworkParamType} from './network.utils';

export default {
  verifyEmail: {
    url: 'member/verify-email/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    params: {
      token: [NetworkParamType.string],
    },
  },
  resendVerifyEmail: {
    url: 'member/resend-verify-email/',
    apiVersion: NetworkAPIVersion.v1_version,
    authorization: true,
    method: 'post',
  },
};
