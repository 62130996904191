import {NetworkAPIVersion, NetworkParamType} from './network.utils';

export default {
  getSources: {
    url: 'source/',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,

    /*
    * - Response:
    - source_name_zh
    - source_name_en
    - source_location: **only in detail mode**
    - source_url: **only in detail mode**
    - source_logo_path
    - supplier: **only in detail mode**
    * */
  },

  searchCategory: {
    url: 'catalog/search/',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,
    params: {
      clan_name: [NetworkParamType.string], //Clan surname
      title: [NetworkParamType.string], //Original Title
      ancestral_place: [NetworkParamType.string], //Ancestral Place
      author: [NetworkParamType.string], //Author name
      clan_branch: [NetworkParamType.string], //Clan Branch
      founding_ancestor: [NetworkParamType.string], //Founding Ancestor
      publication_date: [NetworkParamType.number], //Publication Date
      format: [NetworkParamType.boolean], //Has zupu image or not
      source: [NetworkParamType.array], //Source Id,
      limit: [NetworkParamType.number],
      offset: [NetworkParamType.number],
    },
  },

  getCategoryDetail: {
    url: 'catalog/',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,
    restful: true,
    params: {
      ID: [NetworkParamType.string],
    },
  },

  /*
  * - Get /api/v1/catalog/search/
- Get detail /api/v1/catalog/{catalog-id}/
- Parameters:
    - clan_name: [NetworkParamType.string, Clan surname
    - title: [NetworkParamType.string, Original Title
    - ancestral_place: [NetworkParamType.string, Ancestral Place
    - author: [NetworkParamType.string, Author name
    - clan_branch: [NetworkParamType.string, Clan Branch
    - founding_ancestor: [NetworkParamType.string, Founding Ancestor
    - publication_date: integer, Publication Date
    - format: Has zupu image or not
    - source: Source Id
- Response:
    - original_title
    - original_title_pinyin
    - author_name
    - author_name_pinyin
    - publication_date
    - ancestral_place
    - ancestral_place_pinyin
    - source
        - source_logo_path
        - source_name_zh
        - source_name_en
        - source_location: **only in detail mode**
        - source_url: **only in detail mode**
        - source_facilitator: supplier *only in detail mode*
    - clan: **only in detail mode**
        - name_ch
        - pinyin
    - clan_branch: Clan Branch, **only in detail mode**
    - founding_ancestor: Founding Ancestor, **only in detail mode**
    - date_covered: Date Covered, **only in detail mode**
    - abstract: Summary, **only in detail mode**
    - publisher: Publisher, **only in detail mode**
    - publication_location: **only in detail mode**
    - isbn: ISBN, **only in detail mode**
    - page_number: Number of Items, **only in detail mode**
    - volume_number: Number of Volumes, **only in detail mode**
    - roll_number: Number of Rolls,  **only in detail mode**
    - printing_type: Printing Type,  **only in detail mode**
    - comment: Notes, optional, **only in detail mode**
    - mcr_zupu_id: 对应的ClanPedigree族谱数据, 用于缩略图跳转 **only in detail mode**
    - cover_id: **only in detail mode**
  * */
};
