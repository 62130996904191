export default {
  savedClanPedigreeListState(state) {
    return state.list || [];
  },
  savedClanPedigreeListMetaState(state) {
    return state.listMeta;
  },
  savedClanPedigreeListLoadingState(state) {
    return state.listLoading;
  },
  savedClanPedigreeCreateLoadingState(state) {
    return state.createLoading;
  },
  savedClanPedigreeDeleteLoadingState(state) {
    return state.deleteLoading;
  },
};
