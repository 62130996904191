import network from '@common/network/network';

export default {
  createMentionAction(context, {personId, imageIds, sourceId}) {
    imageIds = imageIds ? imageIds.join(',') : '';
    return new Promise((resolve, reject) => {
      network.source
        .createMention({image_ids: imageIds, person_id: personId, source_id: sourceId})
        .then(response => {
          resolve(response);
        })
        .catch(reject);
    });
  },
  publishMentionsAction(context, {personId, sourceId}) {
    return new Promise((resolve, reject) => {
      network.source
        .publishMentions({person_id: personId, source_id: sourceId})
        .then(response => {
          resolve(response);
        })
        .catch(reject);
    });
  },
  getPersonMentionsAction(context, {code}) {
    context.commit('setPersonMentionsLoadingState', true);
    return new Promise((resolve, reject) => {
      network.source
        .getPersonMentions({code})
        .then(response => {
          context.commit('setPersonMentionsState', response);
          resolve(response);
        })
        .finally(() => {
          context.commit('setPersonMentionsLoadingState', false);
        })
        .catch(err => {
          context.commit('setPersonMentionsState', null);
          reject(err);
        });
    });
  },
  getPersonMentionsImagesAction(context, {code, sourceId, page, forHint = false}) {
    page = page || 1;
    const limit = 100;
    const offset = limit * (page - 1);
    context.commit('setPersonMentionsImagesLoadingState', true);
    return new Promise((resolve, reject) => {
      network.source
        .getPersonMentionsImages({code, source_id: sourceId, limit, offset, for_hint: forHint})
        .then(response => {
          const meta = context.getters.personMentionsImagesMetaState;
          if (page > 1 && code === meta.code && sourceId === meta.source_id) {
            context.commit('addToPersonMentionsImagesState', response.objects);
          } else {
            context.commit('setPersonMentionsImagesState', response.objects);
          }
          context.commit('setPersonMentionsImagesMetaState', response.meta);
          resolve(response);
        })
        .finally(() => {
          context.commit('setPersonMentionsImagesLoadingState', false);
        })
        .catch(() => {
          context.commit('setPersonMentionsImagesState', null);
          context.commit('setPersonMentionsImagesMetaState', null);
        });
    });
  },
  fetchMentionsOptionsAction(context) {
    context.commit('setMentionsOptionsLoadingState', true);
    return new Promise((resolve, reject) => {
      network.source
        .searchMentionsOptions()
        .then(response => {
          context.commit('setMentionsOptionsState', response);
          resolve(response);
        })
        .finally(() => {
          context.commit('setMentionsOptionsLoadingState', false);
        });
    });
  },
};
