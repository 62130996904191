import network from '@common/network/network';

export default {
  saveMentionToFamilyTreeAction(context, {mention_id, person_id, family_tree_id, saved_mention_id}) {
    return new Promise((resolve, reject) => {
      network.familyTreeSaved
        .saveMention({mention_id, person_id, family_tree_id, saved_mention_id})
        .then(resolve)
        .catch(reject);
    });
  },
  deleteSavedMentionFromFamilyTreeAction(context, {id}) {
    return new Promise((resolve, reject) => {
      network.familyTreeSaved.deleteSavedMention({id}).then(resolve).catch(reject);
    });
  },
  fetchFamilyTreeSavedMentionsPersonIdsAction(context, {family_tree_id}) {
    context.commit('setFamilyTreeSavedMentionsPersonIdsState', []);
    return new Promise((resolve, reject) => {
      network.familyTreeSaved
        .getSavedMentionsPersonIds({family_tree_id})
        .then(response => {
          context.commit('setFamilyTreeSavedMentionsPersonIdsState', response);
          resolve(response);
        })
        .catch(reject);
    });
  },
  fetchFamilyTreePersonSavedMentionsAction(context, {person_id, page}) {
    context.commit('setFamilyTreePersonSavedMentionsLoadingState', true);
    page = page || 1;
    const limit = 10;
    const offset = limit * (page - 1);
    return new Promise((resolve, reject) => {
      network.familyTreeSaved
        .getSavedMentions({person_id, limit, offset})
        .then(response => {
          context.commit('setFamilyTreePersonSavedMentionsState', response.objects);
          context.commit('setFamilyTreePersonSavedMentionsMetaState', response.meta);
        })
        .finally(() => {
          context.commit('setFamilyTreePersonSavedMentionsLoadingState', false);
        });
    });
  },

  fetchSavedMentionsAction(context, {page}) {
    context.commit('setFamilyTreeSavedMentionsLoadingState', true);
    page = page || 1;
    const limit = 10;
    const offset = limit * (page - 1);
    return new Promise((resolve, reject) => {
      network.familyTreeSaved
        .getSavedMentions({limit, offset})
        .then(response => {
          context.commit('setFamilyTreeSavedMentionsState', response.objects);
          context.commit('setFamilyTreeSavedMentionsMetaState', response.meta);
        })
        .finally(() => {
          context.commit('setFamilyTreeSavedMentionsLoadingState', false);
        });
    });
  },
};
