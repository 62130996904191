export default {
  setFullPathBeforeLoginRegisterState(state, fullPath) {
    state.fullPathBeforeLoginRegister = fullPath;
  },
  addReviewedItemState(state, identifier) {
    if (!state.reviewedItems.includes(identifier)) {
      state.reviewedItems = [...state.reviewedItems, identifier];
    }
  },
  removeReviewedItemState(state, identifier) {
    state.reviewedItems = state.reviewedItems.filter(item => item !== identifier);
  },
  dismissNotificationInState(state, identifier) {
    if (state.dismissedNotifications.includes(identifier)) {
      return;
    }
    state.dismissedNotifications = [...state.dismissedNotifications, identifier];
  },
  setNotificationsState(state, list) {
    state.notifications = list;
  },
  addNotificationState(state, notification) {
    state.notifications = [...state.notifications, notification];
  },
  setClanPedigreeDetailsPreviousRouteState(context, route) {
    const {fullPath, meta, name, params, path, query} = route;
    context.clanPedigreeDetailsPreviousRoute = {fullPath, meta, name, params, path, query};
  },
  setPreviousRouteState(context, route) {
    context.previousRoute = route;
  },

  setShowCardQuickEditTooltipState(state, boolean) {
    state.showCardQuickEditTooltip = boolean;
  },
  setShowCardQuickViewTooltipState(state, boolean) {
    state.showCardQuickViewTooltip = boolean;
  },
  setShowCardFullProfileTooltipState(state, boolean) {
    state.showCardFullProfileTooltip = boolean;
  },
  setShowCardAddRelativeTooltipState(state, boolean) {
    state.showCardAddRelativeTooltip = boolean;
  },
  setNextVerifyNotificationTimeState(state, datetime) {
    state.nextVerifyNotificationTime = datetime;
  },
};
