import {NetworkAPIVersion, NetworkParamType} from './network.utils';

export default {
  details: {
    url: 'familytree/{id}/lineage/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
    params: {
      start_person_id: [NetworkParamType.number],
    },
  },
};
