export default {
  placeAlternativesState(state) {
    return state.placeAlternatives;
  },
  placeAlternativesLoadingState(state) {
    return state.placeAlternativesLoading;
  },

  placeSearchState(state) {
    return state.placeSearch || [];
  },
  placeSearchMetaState(state) {
    return state.placeSearchMeta;
  },
  placeSearchLoadingState(state) {
    return state.placeSearchLoading;
  },
  placeSearchStartTimeState(state) {
    return state.placeSearchStartTime || 0;
  },
  placeSearchQueryState(state) {
    return state.placeSearchQuery;
  },
  placeSearchRootPlaceState(state) {
    return state.placeSearchRootPlace;
  },
  placeSearchMigrationPlaceState(state) {
    return state.placeSearchMigrationPlace;
  },
  placeSearchClanState(state) {
    return state.placeSearchClan;
  },
  placeSearchLevelState(state) {
    return state.placeSearchLevel;
  },

  placeOverseasSearchState(state) {
    return state.placeOverseasSearch || [];
  },
  placeOverseasSearchLoadingState(state) {
    return state.placeOverseasSearchLoading;
  },
  placeOverseasSearchMetaState(state) {
    return state.placeOverseasSearchMeta;
  },
  placeOverseasSearchQueryState(state) {
    return state.placeOverseasSearchQuery;
  },

  placeState(state) {
    return state.place;
  },
  placeLoadingState(state) {
    return state.placeLoading;
  },
  placeDescendantsSearchState(state) {
    return state.placeDescendantsSearch || [];
  },
  placeDescendantsSearchMetaState(state) {
    return state.placeDescendantsSearchMeta;
  },
  placeDescendantsSearchLoadingState(state) {
    return state.placeDescendantsSearchLoading;
  },
};
