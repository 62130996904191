import trim from 'lodash/trim';

export function setCookie(name, value, expire_hours) {
  let d = new Date();
  d.setTime(d.getTime() + expire_hours * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  document.cookie = name + '=' + value + ';' + expires + ';path=/';
}

export function getCookie(name) {
  const cookies = decodeURIComponent(document.cookie);
  let found = cookies
    .split(';')
    .map(pair => trim(pair))
    .map(pair => pair.split('='))
    .map(pair => ({name: pair[0], value: pair[1]}))
    .filter(pair => pair.name === name);
  return found.length ? found[0].value : null;
}
