<template>
  <div class="info-banner" @click="click">
    <div class="icon-container">
      <creation-icon :size="12"></creation-icon>
    </div>
    <div class="label text-md text-md-mobile">{{ label }}</div>
    <div class="close" @click.stop="close"><close-icon :size="20" /></div>
  </div>
</template>

<script>
import CloseIcon from 'vue-material-design-icons/Close';
import CreationIcon from 'vue-material-design-icons/Creation';

export default {
  props: {
    label: String,
    tutorialId: String,
  },
  methods: {
    click() {
      this.$emit('click');
    },
    close() {
      if (this.tutorialId) {
        this.$store.dispatch('updateTutorialsShownAction', {tutorial_id: this.tutorialId});
      }
      this.$emit('close');
    },
  },
  components: {CreationIcon, CloseIcon},
  name: 'InfoBanner',
};
</script>

<style scoped lang="scss">
.info-banner {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: $neutral-50;
  padding: 16px 40px 16px 20px;
  position: relative;
  column-gap: 8px;
  cursor: pointer;

  .icon-container {
    background: $primary-50;
    color: $primary-600;
    border-radius: 100%;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .label {
    color: $neutral-600;
  }

  .close {
    position: absolute;
    right: 20px;
    cursor: pointer;
    top: 18px;
    display: flex;
    color: $neutral-500;
  }

  @media only screen and (max-width: $main-menu-breakpoint) {
    padding: 16px 36px 16px 16px;

    .close {
      top: 16px;
      right: 16px;
    }
  }
}
</style>
