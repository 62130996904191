import network from '@common/network/network';
import {isChineseText} from '@common/utils/utils';

function splitNames(person, separateOtherNames) {
  if (separateOtherNames) {
    person.first_names_en = person.first_names.filter(name => name.type !== 'other_name' && !isChineseText(name.value));
    person.first_names_cn = person.first_names.filter(name => name.type !== 'other_name' && isChineseText(name.value));
    person.other_names = person.first_names.filter(name => name.type === 'other_name');
  } else {
    person.first_names_en = person.first_names.filter(name => !isChineseText(name.value));
    person.first_names_cn = person.first_names.filter(name => isChineseText(name.value));
  }
  person.surnames_en = person.surnames.filter(name => !isChineseText(name.value));
  person.surnames_cn = person.surnames.filter(name => isChineseText(name.value));
}

export default {
  fetchFamilyTreeHintsAction(context, {family_tree_id, person_id, status, page}) {
    const limit = 10;
    const offset = limit * (page - 1);
    context.commit('setFamilyTreeHintsLoadingState', true);
    return new Promise((resolve, reject) => {
      network.familyTreeHints
        .list({family_tree_id, person_id, status, limit, offset})
        .then(response => {
          context.commit('setFamilyTreeHintsListState', response.objects);
          context.commit('setFamilyTreeHintsMetaState', {...response.meta, person_id});
          if (response.meta.family_tree) {
            context.commit('setActiveFamilyTreeIdState', response.meta.family_tree.id);
          }
        })
        .catch(error => {
          if (parseInt(context.getters.activeFamilyTreeIdState) === parseInt(family_tree_id)) {
            context.commit('clearActiveFamilyTreeIdState');
          }
          reject(error);
        })
        .finally(() => {
          context.commit('setFamilyTreeHintsLoadingState', false);
        });
    });
  },

  fetchFamilyTreeHintDetailsAction(context, id) {
    context.commit('setFamilyTreeHintDetailsLoadingState', true);
    return new Promise((resolve, reject) => {
      network.familyTreeHints
        .details({id})
        .then(response => {
          splitNames(response.person, false);
          splitNames(response.match_person, true);

          context.commit('setFamilyTreeHintDetailsState', response);
          resolve(response);
        })
        .catch(reject)
        .finally(() => {
          context.commit('setFamilyTreeHintDetailsLoadingState', false);
        });
    });
  },

  ignoreFamilyTreeHintAction(context, {id, ft_id}) {
    context.commit('setFamilyTreeHintIgnoreLoading', {id, loading: true});
    return new Promise((resolve, reject) => {
      network.familyTreeHints
        .ignore({id})
        .then(res => {
          context.commit('decrementUserHintsTreeIdsState', ft_id);
          resolve(res);
        })
        .catch(reject)
        .finally(() => {
          context.commit('setFamilyTreeHintIgnoreLoading', {id, loading: false});
        });
    });
  },
  acceptFamilyTreeHintAction(context, {id, data, ft_id}) {
    context.commit('setFamilyTreeHintAcceptLoading', {id, loading: true});
    return new Promise((resolve, reject) => {
      network.familyTreeHints
        .accept({id, data})
        .then(res => {
          context.commit('decrementUserHintsTreeIdsState', ft_id);
          resolve(res);
        })
        .catch(reject)
        .finally(() => {
          context.commit('setFamilyTreeHintAcceptLoading', {id, loading: false});
        });
    });
  },
  fetchFamilyTreeHintsPersonIdsAction(context, {family_tree_id}) {
    return new Promise((resolve, reject) => {
      network.familyTreeHints
        .personIds({family_tree_id})
        .then(response => {
          context.commit('setFamilyTreeHintsCountsByPersonIdsState', response);
          resolve(response);
        })
        .catch(reject);
    });
  },
};
