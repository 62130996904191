import {getAsset} from '@common/utils/utils';

export default {
  list: [
    {
      quote:
        'I first visited my grandfather’s village when I was 19, just starting to explore my roots. Returning now with my own children, I know they will look back on this trip in years to come with a wider perspective of the world.',
      imgSource: getAsset('/assets/services/testimonials/ErikaLeeweb.jpeg'),
      name: 'Erika Lee',
      description: 'Author, <em>The Making of Asian America</em>',
    },
    {
      quote:
        'For years, My China Roots has been very successful in providing bespoke, emotional discovery journeys. With their online platform, I’m confident that they’ll help Chinese Overseas everywhere!',
      imgSource: getAsset('/assets/services/testimonials/Paula-Madison-CAAM-square-300x300.jpg'),
      name: 'Paula Madison',
      description: 'Executive Producer, <em>Finding Samuel Lowe: From Harlem to China</em>',
    },
    {
      quote:
        'It took a few wrong turns before we discovered Mei-Ling’s grandfather’s village, and met relatives we never knew we had! We are thankful to MCR for their help along the way! ',
      imgSource: getAsset('/assets/services/testimonials/JohnGriffiths.jpg'),
      name: 'John',
      description: 'Museum of Australian Chinese History',
    },
    {
      quote:
        'I was skeptical. My ancestors left Fujian seven generations ago, and our clan book was destroyed during the Cultural Revolution. However, MCR found it 4000 km away... in Singapore!',
      imgSource: getAsset('/assets/services/testimonials/nio-300x300.jpg'),
      name: 'Nio',
      description: 'Singapore',
    },
    {
      quote:
        'A wonderful, personal journey that unfolded along with the search for my grandfather. My roots are somehow real now. They are part of me.',
      imgSource: getAsset('/assets/services/testimonials/Lesley-Delapaz-photo-square-1-300x300.jpg'),
      name: 'Lesley',
      description: 'Dominican Republic + USA',
    },
    {
      quote:
        'After my trip back, I now truly appreciate the struggles my forefathers endured for the good life I am living. A highlight was meeting my grandfather’s cousin live on Skype, while MCR helped us translate!',
      imgSource: getAsset('/assets/services/testimonials/IMG_1978-square.jpg'),
      name: 'Nicholas',
      description: 'Indonesia + Netherlands',
    },
    {
      quote: 'A tangible connection to the past that runs in my own bloodline, 2000 years back!',
      imgSource: getAsset('/assets/services/testimonials/adam.jpg'),
      name: 'Adam',
      description: 'Australia',
    },
    {
      quote:
        "Our trip to our ancestral homes was unforgettable, and so different to the research I'd previously done on the family. I wish we had more time to explore our villages!",
      imgSource: getAsset('/assets/services/testimonials/Linda_cropped.jpg'),
      name: 'Linda',
      description: 'Canada',
    },
    {
      quote:
        'When he was diagnosed with cancer, our 14 yo son had one wish: to visit China, the land of his great-grandfather. Our trip led us to reconnect with family not only in China, but also in England and Canada!',
      imgSource: getAsset('/assets/services/testimonials/Gary_sydoni.jpg'),
      name: 'Gary & Sydoni',
      description: 'Jamaica + USA',
    },
    {
      quote:
        'MCR helped me overcome the brick walls in my search. In no time, they discovered my ancestral village and connected me with my past and present relatives.',
      imgSource: getAsset('/assets/client_pics/Jesse_profile.png'),
      name: 'Jesse',
      description: 'Canada',
    },
    {
      quote:
        'These guys know what they are doing! They were prompt and professional when connecting my family with our relative in China. Just the look on his face, and the comments I have gotten after the call, have all made it worth it. I cannot thank the team enough!',
      imgSource: getAsset('/assets/services/testimonials/XavierMurphy.png'),
      name: 'Xavier',
      description: 'Founder of Jamaicans.com',
    },
    {
      quote:
        'Knowing you are part of something greater than yourself is truly moving and profound. I cannot speak highly enough of My China Roots: the research they offer is professional, comprehensive, and the final report is amazing.',
      imgSource: getAsset('/assets/client_pics/geraldchen.jpg'),
      name: 'Gerald',
      description: 'Canada',
    },
    {
      quote:
        ' Before, I just knew I had Chinese blood, but now I can prove it. I am so thankful to MCR for helping us reconnect our long-lost roots to a rich history going back hundreds of years of Yap lineage!',
      imgSource: getAsset('/assets/client_pics/Rikoyap2.png'),
      name: 'Riko',
      description: 'USA + Philippines',
    },
  ],
};
