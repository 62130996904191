const searchSourcesForm = {
  q: null,
  auto_place: null,
  country: null,
  clan_name: null,
  ancestral_place: null,
  ancestral_level_1: null,
  ancestral_level_2: null,
  ancestral_level_3: null,
  publication_year: null,
  category_id: null,
  source_type: null,
  record_format: null,
  has_ocr: null,
  has_images: null,
  collection: null,
};

export default {
  images: [],
  imagesOcr: [],
  imageLoading: false,
  imageOcrLoading: false,

  imagesSearchLoading: false,
  imagesSearchResults: null,
  imagesSearchMeta: null,

  source: null,
  sourceLoading: false,

  collection: null,
  collectionLoading: null,

  deleteImagesLoading: false,

  searchAllSourcesOptions: null,
  searchAllSourcesOptionsLoading: false,
  searchAllSourcesForm: {...searchSourcesForm},
  searchAllSourcesLoading: false,
  searchAllSourcesList: [],
  searchAllSourcesMeta: {},

  ocrLangBySourceId: {},

  sourcesAutocomplete: [],
  sourcesAutocompleteLoading: false,

  searchCategories: [],
  searchCategoriesLoading: false,
  searchCategory: null,
  searchCategoryLoading: false,
};
