import {NetworkAPIVersion, NetworkParamType} from './network.utils';

export default {
  getDetailed: {
    url: 'spelling/{spelling}/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
  },
  search: {
    url: 'spelling/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
    params: {
      limit: [NetworkParamType.number],
      offset: [NetworkParamType.number],
      place_id: [NetworkParamType.string],
      order_by: [NetworkParamType.string],
    },
  },
};
