export default {
  id: null,
  firstName: null,
  lastName: null,
  email: null,
  interests: [],
  otherComment: '',
  attachments: [],
  requestInfo: {},
  ancestor: {
    relation: null,
    firstName: null,
    lastName: null,
  },
  additionalInfo: {},
  subscribeToNewsletter: false,
  initData: {},
  loading: false,
  loadingProgress: 0,
};
