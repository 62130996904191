import network from '@common/network/network';

export default {
  createStripeCheckoutSessionAction(context) {
    context.commit('setStripeCheckoutSessionLoadingState', true);
    return new Promise((resolve, reject) => {
      network.payment
        .createCheckoutSession()
        .then(response => {
          context.commit('setStripeCheckoutSessionIdState', response.session_id);
          resolve(response);
        })
        .catch(() => {
          context.commit('setStripeCheckoutSessionLoadingState', false);
          reject();
        });
    });
  },
};
