export default {
  familyWebsiteListState(state) {
    return state.list;
  },
  familyWebsiteListLoadingState(state) {
    return state.listLoading;
  },
  familyWebsiteDetailsState(state) {
    return state.details;
  },
  familyWebsiteDetailsLoadingState(state) {
    return state.detailsLoading;
  },
};
