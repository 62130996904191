export default {
  familyTreeLineageState(state) {
    return state.lineage;
  },
  familyTreeLineageLoadingState(state) {
    return state.lineageLoading;
  },
  familyTreeLineageStartPersonIdState(state) {
    return state.startPersonId;
  },
};
