export default {
  list: null,
  meta: null,
  loading: false,

  detailsLoading: false,
  details: null,
  detailsPreviousRoute: null,
  ignoreLoading: {},
  acceptLoading: {},

  newByPersonIds: {},
};
