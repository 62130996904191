import network from '@common/network/network';

export default {
  fetchClanPedigreesMetaListAction(context, {clan_id, place_ids = []}) {
    context.commit('setClanPedigreesMetaListLoadingState', true);
    return new Promise((resolve, reject) => {
      network.pedigree
        .clanPedigreesMeta({clan_id, place_ids: place_ids.join(',')})
        .then(response => {
          context.commit('setClanPedigreesMetaListState', response);
          resolve(response);
        })
        .finally(() => {
          context.commit('setClanPedigreesMetaListLoadingState', false);
        });
    });
  },
  getClanPedigreeDetailsAction(context, clanPedigreeId) {
    context.commit('setClanPedigreeDetailsLoadingState', true);
    return new Promise((resolve, reject) => {
      network.pedigree
        .getClanPedigreeDetails({id: clanPedigreeId})
        .then(response => {
          context.commit('setClanPedigreeDetailsState', response);
          resolve(response);
        })
        .catch(err => {
          context.commit('setClanPedigreeDetailsState', null);
          reject(err);
        })
        .finally(() => {
          context.commit('setClanPedigreeDetailsLoadingState', false);
        });
    });
  },
};
