import {NetworkAPIVersion} from '@common/network/network.utils';

export default {
  get: {
    url: 'switches/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
    noImpersonation: true,
  },
};
