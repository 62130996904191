export default {
  ownedClanPedigreeListState(state) {
    return state.list || [];
  },
  ownedClanPedigreeListMetaState(state) {
    return state.listMeta;
  },
  ownedClanPedigreeListLoadingState(state) {
    return state.listLoading;
  },
  buyZupuLoadingState(state) {
    return state.buyZupuLoading;
  },
};
