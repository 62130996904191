import {NetworkAPIVersion, NetworkParamType} from './network.utils';

export default {
  getFamilyWebSiteList: {
    url: 'familywebsite/?order_by=priority&order_by=-created_at',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,
    params: {
      order_by: [NetworkParamType.array],
      limit: [NetworkParamType.number],
      offset: [NetworkParamType.number],
      username: [NetworkParamType.string],
    },
    authorization: true,
    fws: true,
  },
  getFamilyWebSiteListByName: {
    url: 'familywebsite/get-by-name/',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,
    params: {
      username: [NetworkParamType.string],
      site_name: [NetworkParamType.string],
      keep_image_asset_id: [NetworkParamType.string],
      part: [NetworkParamType.array],
    },
    authorization: true,
    fws: true,
  },
};
