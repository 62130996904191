export default {
  savedMentionsPerson: null,
  savedMentionsPersonMeta: null,
  savedMentionsPersonLoading: false,

  savedMentions: null,
  savedMentionsMeta: null,
  savedMentionsLoading: false,

  savedMentionsPersonIds: [],
};
