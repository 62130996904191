import {NetworkAPIVersion, NetworkParamType} from './network.utils';

export default {
  searchPedigree: {
    url: 'clan-pedigree/my-pedigree/',
    method: 'get',
    authorization: true,
    apiVersion: NetworkAPIVersion.v1_version,
    params: {
      offset: [NetworkParamType.number],
      clan_id: [NetworkParamType.string],
      clan_name: [NetworkParamType.string],
      place_id: [NetworkParamType.string],
      limit: [NetworkParamType.number],
      only_meta: [NetworkParamType.boolean],
    },
  },
  clanPedigreesMeta: {
    url: 'clan-pedigree/meta/',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,
    params: {
      clan_id: [NetworkParamType.string],
      place_ids: [NetworkParamType.array],
    },
  },
  clanPedigreesCount: {
    url: 'clan-pedigree/count/',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,
  },
  getClanPedigreeDetails: {
    url: '/api/v1/clan-pedigree/{id}/',
    method: 'get',
    apiVersion: NetworkAPIVersion.noVersion,
  },
  searchPedigreeContent: {
    url: 'clan-pedigree-image/search/',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,
    params: {
      clan_pedigree_id: [NetworkParamType.string],
      q: [NetworkParamType.string],
      offset: [NetworkParamType.number],
    },
  },
  getClanPedigreePage: {
    url: 'clan-pedigree/{id}/page/{pageNumber}/',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,
    params: {
      search: [NetworkParamType.string],
    },
  },
  getClanPedigreePageOCR: {
    url: 'clan-pedigree/{id}/page-ocr/{pageNumber}/?ocr_type={ocrType}',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,
  },
  getClanPedigreePagePreview: {
    url: 'clan-pedigree/{id}/page-preview/{pageNumber}/',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,
    responseType: 'arraybuffer',
  },
  getClanPedigreePageSearchPreview: {
    url: 'clan-pedigree/{id}/page-search-preview/{pageNumber}/',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,
    responseType: 'arraybuffer',
    params: {
      search: [NetworkParamType.string],
      is_horizontal: [NetworkParamType.boolean],
    },
  },
  requestClanPedigreeBuy: {
    url: 'clan-pedigree/{id}/request-buy/',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,
  },
  searchClanPedigreesPagesContent: {
    url: 'clan-pedigree/search-content/',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,
    params: {
      clan_id: [NetworkParamType.string],
      clan_name: [NetworkParamType.string],
      place_id: [NetworkParamType.string],
      q: [NetworkParamType.string],
      limit: [NetworkParamType.number],
      offset: [NetworkParamType.number],
      only_meta: [NetworkParamType.boolean],
    },
  },
  getClanPedigreeImageWithBounds: {
    url: 'clan-pedigree/clan-pedigree-image-with-bounds/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
    responseType: 'arraybuffer',
    params: {
      clan_pedigree_id: [NetworkParamType.string],
      page_number: [NetworkParamType.string],
      clan_pedigree_image_id: [NetworkParamType.string],
    },
  },
};
