import {initStripeScript} from '@common/utils/script.loader';

export const stripe = {};

stripe.install = function (Vue) {
  const setStripe = () => {
    Vue.prototype.$stripe = (function () {
      return {
        init: () => {},
        ...Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY),
      };
    })();
  };

  const init = () => {
    Vue.prototype.$stripe = {init: () => {}};
    return initStripeScript().then(setStripe);
  };

  Vue.prototype.$stripe = {
    init: init,
  };
};
