import {NetworkAPIVersion, NetworkParamType} from './network.utils';

export default {
  uploadAttachment: {
    url: 'consult-attachment/upload/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    multipart: true,
    authorization: true,
    params: {
      attachment: [NetworkParamType.file],
    },
  },

  createConsultCase: {
    url: 'consult-case/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    authorization: true,
    params: {
      attachments: [NetworkParamType.array],
      content: [NetworkParamType.string],
    },
  },
};
