import NotificationsContainer from '@common/elements/notifications/NotificationsContainer';

const NotificationsPlugin = {
  install(Vue, options = {}) {
    if (this.installed) {
      return;
    }
    this.installed = true;
    this.rootInstance = null;

    Vue.mixin({
      beforeMount() {
        if (NotificationsPlugin.rootInstance === null) {
          NotificationsPlugin.rootInstance = this.$root;
        }
      },
    });

    const getContainer = () => {
      if (NotificationsPlugin.rootInstance._notificationsContainer) {
        return NotificationsPlugin.rootInstance._notificationsContainer;
      }
      const container = document.createElement('div');
      document.body.appendChild(container);

      new Vue({
        parent: NotificationsPlugin.rootInstance,
        render: h => h(NotificationsContainer),
      }).$mount(container);

      return NotificationsPlugin.rootInstance._notificationsContainer;
    };

    Vue.prototype.$notification = {
      show(component, props) {
        const container = getContainer();
        if (container) {
          container.show(component, props);
        }
      },
      hide() {
        const container = getContainer();
        if (container) {
          container.hide();
        }
      },
    };
  },
};

export default NotificationsPlugin;
