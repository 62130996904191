export default {
  list: null,
  meta: null,
  loading: null,
  form: {
    q: null,
    last_name: null,
    clan_id: null,
    clan_name: null,
    ancestral_place: null,
    ancestral_level_1: null,
    ancestral_level_2: null,
    ancestral_level_3: null,
    country: null,
    category_id: null,
    source_type: null,
    source_id: null,
    collection: null,
  },
  options: null,
  optionsLoading: null,

  countLoading: false,
  count: null,
};
