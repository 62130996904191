import network from '@common/network/network';
import moment from 'moment/moment';

export default {
  uploadImageToTextAction(context, {file}) {
    const id = moment().unix() + '__' + file.lastModified + '__' + file.name;
    const fileData = {
      _id: id,
      progress: '0',
      error: false,
      uploading: true,
      attachment: '',
      filename: file.name,
      name: file.name,
      size: file.size,
      uploadingPreviewSrc: /image/g.test(file.type) ? URL.createObjectURL(new Blob([file])) : '',
    };
    context.commit('setUploadImageState', fileData);

    return new Promise((resolve, reject) => {
      network.familyTreeLibrary
        .uploadFile({id: 'my', attachment: file, source: 'image to text'}, progressRes => {
          fileData.progress = ((progressRes.loaded / progressRes.total) * 100).toFixed(2);
        })
        .then(response => {
          context.commit('setUploadImageState', {_id: id, ...response});
          resolve(response);
        })
        .catch(error => {
          fileData.error = true;
          reject(error);
        });
    });
  },
  getImageForImageToTextAction(context, {assetId}) {
    context.commit('setImageToTextLoadingState', true);
    return new Promise((resolve, reject) => {
      network.familyTreeLibrary
        .getAssetDetails({id: assetId})
        .then(response => {
          context.commit('setImageToTextState', response);
          resolve(response);
        })
        .catch(reject)
        .finally(() => {
          context.commit('setImageToTextLoadingState', false);
        });
    });
  },
};
