export default {
  clansList: null,
  clansLoading: null,

  searchText: null,
  searchTextStart: null,
  surnamesList: null,
  surnamesMeta: {},
  surnamesLoading: false,
  surnameExists: false,
  surnameExistsLoading: false,
};
