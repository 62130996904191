export default {
  parenthoodCreateLoadingState(state) {
    return state.parenthoodCreateLoading;
  },
  parenthoodCreateErrorsState(state) {
    return state.parenthoodCreateErrors;
  },
  coupleUpdateLoadingState(state) {
    return state.coupleUpdateLoading;
  },
  coupleCreateLoadingState(state) {
    return state.coupleCreateLoading;
  },
  coupleCreateErrorsState(state) {
    return state.coupleCreateErrors;
  },
};
