export default {
  setOrdersListState(state, list) {
    state.list = list;
  },
  setOrdersLoadingState(state, loading) {
    state.listLoading = loading;
  },
  setOrdersMetaState(state, meta) {
    state.listMeta = meta;
  },
};
