import network from '@common/network/network';

export default {
  fetchFamilyWebsiteListAction(context, username) {
    context.commit('setFamilyWebsiteListLoadingState', true);
    return new Promise((resolve, reject) => {
      network.fws
        .getFamilyWebSiteList({limit: 9999, offset: 0, username: username})
        .then(response => {
          context.commit('setFamilyWebsiteListState', response.objects);
          resolve(response.objects);
        })
        .finally(() => {
          context.commit('setFamilyWebsiteListLoadingState', false);
        });
    });
  },
  fetchFamilyWebsiteDetailsAction(context, {username, siteName, partKey, keepImageAssetId = ''}) {
    context.commit('setFamilyWebsiteDetailsLoadingState', true);
    return new Promise((resolve, reject) => {
      network.fws
        .getFamilyWebSiteListByName({
          username,
          site_name: siteName,
          part: [partKey, 'glossary'],
          keep_image_asset_id: keepImageAssetId,
        })
        .then(response => {
          context.commit('setFamilyWebsiteDetailsState', response);
          resolve(response);
        })
        .finally(() => {
          context.commit('setFamilyWebsiteDetailsLoadingState', false);
        });
    });
  },
};
