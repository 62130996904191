// used in vue.config.js, so can't use es6 `export default`
module.exports = {
  desktop: 1280,
  tablet: 920,
  mobile: 768,
  phablet: 660,
  mobileSe: 410,
  mainMenu: 980,
  mainMenuContactButton: 610,
};
