import { render, staticRenderFns } from "./PopupNotifications.vue?vue&type=template&id=7776779c&scoped=true&"
import script from "./PopupNotifications.vue?vue&type=script&lang=js&"
export * from "./PopupNotifications.vue?vue&type=script&lang=js&"
import style0 from "./PopupNotifications.vue?vue&type=style&index=0&id=7776779c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7776779c",
  null
  
)

export default component.exports