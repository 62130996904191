import {NetworkAPIVersion, NetworkParamType} from './network.utils';

export default {
  getDetailed: {
    url: 'cemetery/{id}/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
    params: {
      fields: [NetworkParamType.string],
    },
  },
  getList: {
    url: 'cemetery/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
  },
  searchBurialLocation: {
    url: 'burial-location/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
    autoCancel: true,
    params: {
      q: [NetworkParamType.string],
    },
  },
};
