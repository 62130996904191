export default {
  setWindowWidthState(state, width) {
    state.windowWidth = width;
  },
  setBodyForbidScrollState(state, forbidScroll) {
    state.bodyForbidScroll = Boolean(forbidScroll);
  },
  setNextRefreshTimeState(state, time) {
    state.nextRefreshTime = time;
  },
};
