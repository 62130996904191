import network from '@common/network/network';

export default {
  fetchFamilyTreeLineageAction(context, params = {}) {
    context.commit('setFamilyTreeLineageLoadingState', true);
    params = {...params, id: params.id || 'my'};
    return new Promise((resolve, reject) => {
      network.familyTreeLineage
        .details(params)
        .then(response => {
          context.commit('setFamilyTreeNameState', response.name);
          context.commit('setFamilyTreeHomePerson', response.home_person);
          context.commit('setFamilyTreeDetailsIdState', response.object_id);
          context.commit('setActiveFamilyTreeIdState', response.object_id);
          context.commit('setFamilyTreeIsWriteAllowedState', response.is_write_allowed);
          context.commit('setFamilyTreeIsUserOwnerState', response.is_user_owner);
          context.commit('setFamilyTreeIsPublicState', response.is_public);
          context.commit('setFamilyTreeMembersState', response.tree_members);
          context.commit('setFamilyTreePreferencesState', response.preferences);
          context.commit('setFamilyTreeLineageState', response.ancestors.reverse());
          resolve(response);
        })
        .catch(error => {
          if (parseInt(context.getters.activeFamilyTreeIdState) === parseInt(params.id)) {
            context.commit('clearActiveFamilyTreeIdState');
          }
          reject(error);
        })
        .finally(() => {
          context.commit('setFamilyTreeLineageLoadingState', false);
        });
    });
  },
};
