export default {
  PROD_PROFILE: 'prod',
  store: 'store',
  MAIN_MENU_HEIGHT: 70,
  MAIN_MENU_HEIGHT_MOBILE: 93,
  GENDERS: {f: 'Female', m: 'Male', none: 'Unknown'},
  imageExtensions: ['jpg', 'jpe', 'jpeg', 'png', 'jfif', 'gif', 'svg', 'bmp'],
  videoExtensions: ['mp4', 'webm', 'ogg', 'ogv', 'mov', 'avi', 'wmv', 'flv', '3gp', 'mkv'],
  audioExtensions: ['mp3', 'wav', 'ogg', 'flac', 'aac', 'wma', 'm4a', 'aiff', 'ape', 'alac'],
  gedcomExtensions: ['ged', 'gdz'],
  maxFileSizeMB: 100,
};
