<template>
  <div class="mcr-loading-indicator" v-if="loading">
    <div class="spinner">
      <div class="cube1" :style="cubeColor"></div>
      <div class="cube2" :style="cubeColor"></div>
    </div>
    <div v-if="message" class="loading-message">{{ message }}</div>
  </div>
</template>

<script>
export default {
  name: 'loadingIndicator',
  props: ['loading', 'color', 'message'],

  computed: {
    cubeColor() {
      if (!this.color || this.color.length <= 0) {
        return {};
      }
      return {
        'background-color': this.color,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@common/style/loading.scss';
@import '@common/style/mixins.scss';

.mcr-loading-indicator {
  .loading-message {
    margin-top: 28px;
    font-style: italic;
    color: $supplemental-text-color;
    opacity: 0.8;
  }
}
</style>
