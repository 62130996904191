export default {
  galleryItemsState(state) {
    return state.items;
  },
  galleryMetaState(state) {
    return state.meta;
  },
  galleryItemsCountState(state) {
    return state.items ? state.items.length : 0;
  },
  galleryDisplayItemState(state) {
    return Number.isInteger(state.currentIndex) && state.items[state.currentIndex];
  },
  galleryCurrentIndexState(state) {
    return state.currentIndex;
  },
  galleryHasNextItemState(state) {
    return state.items && state.currentIndex + 1 < state.items.length;
  },
  galleryHasPreviousItemState(state) {
    return state.currentIndex > 0;
  },
};
