import network from '@common/network/network';
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';

function areListsEqual(list1, list2) {
  if (list1.length !== list2.length) {
    return false;
  }
  const sorted1 = [...list1].sort();
  const sorted2 = [...list2].sort();
  return sorted1.every((value, index) => value === sorted2[index]);
}

export default {
  fetchFeatureSwitchesAction(context) {
    let switches = {};
    return new Promise((resolve, reject) => {
      const oldSwitches = context.getters.featureSwitchesState;
      const oldEnabledSwitches = Object.keys(oldSwitches).filter(k => oldSwitches[k]);
      network.switches
        .get()
        .then(response => {
          switches = {
            ...response.flags,
            ...response.switches,
            ...response.samples,
          };
          context.commit('setFeatureSwitchesState', switches);

          const enabledSwitchesNames = Object.keys(switches).filter(k => switches[k]);
          if (!areListsEqual(enabledSwitchesNames, oldEnabledSwitches)) {
            AnalyticsMainHandler.setUserFeaturesProperty(enabledSwitchesNames);
          }
        })
        .catch(() => {})
        .finally(() => {
          context.commit('setFeatureSwitchesLoadedState', true);
          resolve(switches);
        });
    });
  },
};
