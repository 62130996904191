export default {
  setWikiArticleState(state, data) {
    state.article = data;
  },
  setWikiArticleLoadingState(state, loading) {
    state.articleLoading = loading;
  },
  setWikiCategoryState(state, data) {
    state.category = data;
  },
  setWikiCategoryLoadingState(state, loading) {
    state.categoryLoading = loading;
  },
  setWikiSearchState(state, data) {
    state.search = data;
  },
  setWikiSearchMetaState(state, data) {
    state.searchMeta = data;
  },
  setWikiSearchLoadingState(state, loading) {
    state.searchLoading = loading;
  },
  setWikiSearchFormQueryState(state, q) {
    state.searchFormQuery = q;
  },

  setWikiEventsState(state, data) {
    state.wikiEvents = data;
  },
  setWikiEventsLoadingState(state, loading) {
    state.wikiEventsLoading = loading;
  },
};
