export default {
  activeTreeId: null,
  detailsId: null,
  name: null,
  homePerson: null,
  mapImageHash: null,
  mapImage: null,
  mapImageScale: 0.04,
  mapImageLoading: false,
  isMiniMapVisible: null,
  refreshRequest: false,
  startPersonId: null,
  startPersonY: null,
  focusPersonId: null,
  cardMenuShownCardId: null,
  drawLoading: null,
  isWriteAllowed: false,
  isUserOwner: false,
  isPublic: false,
  treeMembers: [],
  preferences: {},
  bloodRelativesIds: [],

  isLangChinese: false,

  treeWidth: null,
  treeHeight: null,
  personsDrawn: null,
  linesDrawn: null,
  treeMarginX: 0,

  list: null,
  listLoading: null,
  updateLoadingById: {},

  personsSearchList: [],
  personsSearchListMeta: null,
  personsSearchListLoading: null,

  mostTaggedLocations: [],
  mostTaggedLocationsLoading: null,

  surnames: [],
  surnamesLoading: null,
  treeClanMeta: {},

  details: null,
  detailsLoading: null,

  treeAddMembersLoading: false,
  treeEditMemberLoading: {},
  treeDeleteMemberLoading: {},

  breadcrumbs: null,
  isExpandControlChanged: false,

  createPrefillSurnames: null,

  allPhotos: [],
};
