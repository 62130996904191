export default {
  contactUsFormState(state) {
    return {
      email: state.email,
      firstName: state.firstName,
      lastName: state.lastName,
      interests: state.interests,
      otherComment: state.otherComment,
      attachments: state.attachments,
      subscribeToNewsletter: state.subscribeToNewsletter,
      requestInfo: state.requestInfo,
    };
  },
  contactUsFormLoadingState(state) {
    return state.loading;
  },
  contactUsFormLoadingProgressState(state) {
    return state.loadingProgress;
  },
  contactUsFormIdState(state) {
    return state.id;
  },
  contactUsFormAncestorState(state) {
    return state.ancestor;
  },
  contactUsFormAdditionalInfoState(state) {
    return state.additionalInfo;
  },
  contactUsFormInitDataState(state) {
    return state.initData;
  },
};
