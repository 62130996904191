import network from '@common/network/network';

export default {
  fetchCemeteryDetailsAction(context, id) {
    context.commit('setCemeteryLoadingState', true);
    return new Promise((resolve, reject) => {
      network.cemetery
        .getDetailed({id})
        .then(response => {
          context.commit('setCemeteryState', response);
          resolve(response);
        })
        .catch(reject)
        .finally(() => {
          context.commit('setCemeteryLoadingState', false);
        });
    });
  },
};
