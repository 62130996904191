<template>
  <iframe class="embeded-google-map lazyload" style="border: 0" :data-src="src" allowfullscreen></iframe>
</template>

<script>
export default {
  props: {
    src: String,
  },
};
</script>

<style lang="scss" scoped>
.embeded-google-map {
  width: 100%;
}
</style>
