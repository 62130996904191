class AnalyticsHandler {
  static postShareClanStoryMap(link) {
    return AnalyticsHandler._postEvent('Share Clan Story Map', 'click', link);
  }

  static postSharePlaceMap(link) {
    return AnalyticsHandler._postEvent('Share Place Map', 'click', link);
  }

  static trackGoToProspectRequestClickEvent(label) {
    return AnalyticsHandler._postEvent('Go to Prospect Request', 'click', label);
  }

  static trackContactSubmittedEvent(formType) {
    return AnalyticsHandler._postEvent('Contact submitted', 'click', formType);
  }

  static trackEmailMCRLinkClickEvent(label) {
    return AnalyticsHandler._postEvent('Email request@mychinaroots.com', 'click', label);
  }

  static trackPlayVideoEvent(label) {
    return AnalyticsHandler._postEvent('Play Video', 'click', label);
  }

  static trackAncestorsSearchButtonClick() {
    return AnalyticsHandler._postEvent('Ancestors Search Button Click', 'click', '');
  }

  static trackAncestorsSearchGetResults() {
    return AnalyticsHandler._postEvent('Ancestors Search Get Results', 'view', '');
  }

  static trackAncestorsSearchResultsClick() {
    return AnalyticsHandler._postEvent('Ancestors Search Results Click', 'click', '');
  }

  static _postEvent(category, action, label) {
    if (window.ga) {
      ga('send', {
        hitType: 'event',
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
      });
    }
  }
}

export default AnalyticsHandler;
