export default {
  windowWidthState(state) {
    return state.windowWidth;
  },
  bodyForbidScrollState(state) {
    return state.bodyForbidScroll;
  },
  nextRefreshTimeState(state) {
    return state.nextRefreshTime || new Date().getTime();
  },
};
