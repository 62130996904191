import Vue from 'vue';

export const prerenderFor = {
  bind(el, binding, vnode) {
    if (!window['__PRERENDER_INJECTED']) {
      return;
    }

    const listName = binding.value.listName;
    const valueName = binding.value.valueName;
    Vue.nextTick(() => {
      el.parentNode.insertBefore(document.createTextNode(`{% for ${valueName} in ${listName} %}`), el);
      el.parentNode.insertBefore(document.createTextNode('{% endfor %}'), el.nextSibling);
    });
  },
};

export const prerenderIf = {
  bind(el, binding, vnode) {
    if (!window['__PRERENDER_INJECTED']) {
      return;
    }

    Vue.nextTick(() => {
      el.parentNode.insertBefore(document.createTextNode(`{% if ${binding.value} %}`), el);
      el.parentNode.insertBefore(document.createTextNode('{% endif %}'), el.nextSibling);
    });
  },
};
