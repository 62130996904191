export default {
  ordersListState(state) {
    return state.list || [];
  },
  ordersLoadingState(state) {
    return state.listLoading;
  },
  ordersMetaState(state) {
    return state.listMeta;
  },
};
