<template>
  <div class="ee-subscription-form">
    <form
      ref="form"
      action="https://api.elasticemail.com/contact/add?version=2"
      data-id="ThKXbMPLch"
      method="post"
      id="ewf_subscriptionForm_ThKXbMPLch"
      class="Inline EWF__form"
      @submit="onSubmit"
    >
      <slot name="header">
        <div class="headers">
          <div class="bold">Stay Updated</div>
          <p>Get the latest resources to stay creative in your roots search. From our family historians to yours!</p>
        </div>
      </slot>

      <slot name="form">
        <div class="email-form-inputs">
          <input required="" name="email" type="email" placeholder="Your Email" />
          <input type="submit" :value="submitLabel" />
        </div>
      </slot>

      <div v-for="emailListItem in emailLists" :class="{hide: hideLists}" class="checkbox-container">
        <input
          type="checkbox"
          name="publiclistid"
          :id="emailListItem.list_public_id"
          :value="emailListItem.list_public_id"
          v-model="checkedListsIds"
        />
        <label :for="emailListItem.list_public_id">{{ emailListItem.name }}</label>
        <div v-if="emailListItem.description" class="description text-sm">{{ emailListItem.description }}</div>
      </div>

      <input type="hidden" name="publicaccountid" value="88931ce4-50c7-4882-8973-710669a4c8b1" />
      <input type="hidden" name="publicformid" value="66da4336-48e6-4860-a640-2213872b67e8" />
      <input type="hidden" name="returnUrl" :value="returnUrl" />
      <input type="hidden" name="activationReturnUrl" :value="activationReturnUrl" />
      <input type="hidden" name="alreadyactiveurl" :value="alreadyActiveUrl" />
      <input type="hidden" name="activationTemplate" value="Blog Newsletter Signup Activation" />
      <input type="hidden" name="source" value="WebForm" />
      <input type="hidden" id="ewf_captcha" name="captcha" value="true" />
      <input type="hidden" name="notifyEmail" value="" />
      <input id="ewf_verifyEmails" type="hidden" name="verifyemail" value="false" />
    </form>
  </div>
</template>

<script>
export default {
  props: {
    submitLabel: String,
    hideLists: {type: Boolean},
    emailLists: {type: Array, default: () => []},
    activeListsIds: {type: Array, default: () => []},
    beforeSubmit: {type: Function, required: false},
  },
  data() {
    const listsIds = this.emailLists.map(checkbox => checkbox.list_public_id);
    return {
      checkedListsIds: this.hideLists ? listsIds : listsIds.filter(id => this.activeListsIds.includes(id)),
    };
  },
  computed: {
    returnUrl() {
      return `${process.env.VUE_APP_WEB_SITE_BASE}/subscription-confirm`;
    },
    activationReturnUrl() {
      return `${process.env.VUE_APP_WEB_SITE_BASE}/subscription-success`;
    },
    alreadyActiveUrl() {
      return `${process.env.VUE_APP_WEB_SITE_BASE}/subscription-success`;
    },
  },
  methods: {
    onSubmit() {
      if (this.beforeSubmit) {
        this.beforeSubmit();
      }
    },
    getCheckboxesState() {
      return this.emailLists.map(checkbox => {
        return {publicListId: checkbox.list_public_id, checked: this.checkedListsIds.includes(checkbox.list_public_id)};
      });
    },
  },
  name: 'ElasticEmailSubscriptionForm',
};
</script>

<style lang="scss" scoped>
.ee-subscription-form {
  min-width: 350px;

  .hide {
    display: none;
  }

  p {
    margin-top: 0;
  }

  .checkbox-container {
    padding: 5px 0;
    input,
    label {
      cursor: pointer;
    }
    .description {
      margin-left: 20px;
      color: $neutral-500;
    }
  }

  .email-form-inputs {
    display: flex;
    width: 100%;
    max-width: 370px;

    input[type='email'] {
      background-color: $dark-input-background;
      border: 1px solid $dark-input-border;
      margin-right: 4px;
      color: $off-white;
      flex-grow: 1;
    }

    input[type='submit'] {
      border: 1px solid rgba(black, 0.5);
    }
  }

  @media only screen and (max-width: 960px) {
    margin-right: 0;
    padding-bottom: 32px;
  }

  @media only screen and (max-width: 540px) {
    min-width: 0;
  }
  @media only screen and (max-width: $breakpoint-mobile) {
    .email-form-inputs {
      flex-direction: column;
      input[type='email'] {
        margin-right: 0;
        margin-bottom: 4px;
      }
    }
  }
}
</style>
