export default {
  personMentionsState(state) {
    return state.mentions;
  },
  personMentionsLoadingState(state) {
    return state.mentionsLoading;
  },
  personMentionsImagesState(state) {
    return state.mentionsImages;
  },
  personMentionsImagesMetaState(state) {
    return state.mentionsImagesMeta || {};
  },
  personMentionsImagesLoadingState(state) {
    return state.mentionsImagesLoading;
  },
  mentionsOptionsState(state) {
    return state.options || {};
  },
  mentionsOptionsLoadingState(state) {
    return state.optionsLoading;
  },
  mentionPrevRouteState(state) {
    return state.mentionPrevRoute;
  },
  areSearchResultsShownState(state) {
    return state.areResultsShown;
  },
};
