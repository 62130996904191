export default {
  sourcesTextSearchListState(state) {
    return state.list || [];
  },
  sourcesTextSearchMetaState(state) {
    return state.meta || {};
  },
  sourcesTextSearchLoadingState(state) {
    return state.loading;
  },
  sourcesTextSearchFormState(state) {
    return state.form;
  },
  sourcesTextSearchOptionsState(state) {
    return state.options || {};
  },
  sourcesTextSearchOptionsLoadingState(state) {
    return state.loading;
  },

  sourcesTextSearchCountState(state) {
    return state.count;
  },
  sourcesTextSearchCountLoadingState(state) {
    return state.countLoading;
  },
};
