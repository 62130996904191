import HintsPaywallModalContent from '@/components/common/modals/HintsPaywallModalContent';

export const PRICING_YEARLY = 'yearly';
export const PRICING_MONTHLY = 'monthly';

export function openHintsPaywallModal(vm) {
  const modalParams = {
    class: 'mobile_bottom',
    classes: 'clear_modal paywall_modal',
    name: HintsPaywallModalContent.modalName,
    height: 'auto',
    scrollable: true,
  };
  vm.$modal.show(HintsPaywallModalContent, {}, modalParams);
}
