export default {
  mentions: null,
  mentionsLoading: false,
  mentionsImages: null,
  mentionsImagesMeta: null,
  mentionsImagesLoading: false,
  options: null,
  optionsLoading: null,
  mentionPrevRoute: null,
  areResultsShown: false,
};
