export default {
  dictionaryWordsState(state) {
    return state.words;
  },
  dictionaryChineseWordsSetState(state) {
    let chineseWords = new Set();
    state.words.forEach(wordData => {
      chineseWords.add(wordData.simplified);
      chineseWords.add(wordData.traditional);
    });
    return chineseWords;
  },

  dictionaryWordsLoadingState(state) {
    return state.wordsLoading;
  },
};
