import {NetworkAPIVersion, NetworkParamType} from './network.utils';

export default {
  login: {
    url: 'member/login/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    noImpersonation: true,
    params: {
      email: [NetworkParamType.string],
      password: [NetworkParamType.string],
      surnames: [NetworkParamType.array],
    },
  },
  ipAddressLogin: {
    url: 'member/ip-address-login/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    noImpersonation: true,
    params: {
      email: [NetworkParamType.string],
      password: [NetworkParamType.string],
      surnames: [NetworkParamType.array],
    },
  },
  register: {
    url: 'member/register/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    noImpersonation: true,
    params: {
      email: [NetworkParamType.string],
      password: [NetworkParamType.string],
      password_confirm: [NetworkParamType.string],
      subscribe_to_newsletter: [NetworkParamType.boolean],
      entry_point_url: [NetworkParamType.string],
      entry_point_name: [NetworkParamType.string],
      first_name: [NetworkParamType.string],
      surname: [NetworkParamType.string],
      surnames: [NetworkParamType.array],
      referral_code: [NetworkParamType.string],
    },
  },
  sendForgotPwdEmail: {
    url: 'member/forgot-password/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    noImpersonation: true,
    params: {
      email: [NetworkParamType.string],
    },
  },
  resetPassword: {
    url: 'member/reset-password/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    authorization: true,
    noImpersonation: true,
    params: {
      token: [NetworkParamType.string],
      password: [NetworkParamType.string],
      password_confirm: [NetworkParamType.string],
    },
  },
  resendVerifyEmail: {
    url: 'member/resend-verify-email/',
    apiVersion: NetworkAPIVersion.v1_version,
    noImpersonation: true,
    authorization: true,
    method: 'post',
  },
  myInformation: {
    url: 'member/me/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
    noImpersonation: true,
    authorization: true,
  },
  customerPortalUrl: {
    url: 'member/customer-portal-url/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
  },
  getTutorialsShown: {
    url: 'member/tutorials-shown/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
  },
  updateTutorialsShown: {
    url: 'member/tutorials-shown/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    params: {
      tutorial_id: [NetworkParamType.string],
    },
  },
  refresh: {
    url: 'member/refresh/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
  },
  changeAvatar: {
    url: 'member/upload-avatar/',
    apiVersion: NetworkAPIVersion.v1_version,
    authorization: true,
    noImpersonation: true,
    multipart: true,
    method: 'post',
    params: {
      photo: [NetworkParamType.file],
    },
  },
  changePassword: {
    url: 'member/change-password/',
    apiVersion: NetworkAPIVersion.v1_version,
    noImpersonation: true,
    method: 'post',
    authorization: true,
    params: {
      old_password: [NetworkParamType.string],
      password: [NetworkParamType.string],
      password_confirm: [NetworkParamType.string],
    },
  },
  changeUserInformation: {
    url: 'member/{id}/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'patch',
    authorization: true,
    noImpersonation: true,
    params: {
      color_code_gender: [NetworkParamType.boolean],
    },
  },
  changeData: {
    url: '',
    apiVersion: NetworkAPIVersion.noVersion,
    method: 'patch',
    authorization: true,
    noImpersonation: true,
    params: {
      photo: [NetworkParamType.string],
      first_name: [NetworkParamType.string],
      last_name: [NetworkParamType.string],
      surname_ch: [NetworkParamType.string],
      surname_en: [NetworkParamType.string],
      ancestral_province: [NetworkParamType.string],
      ancestral_prefecture_city: [NetworkParamType.string],
      ancestral_district_county: [NetworkParamType.string],
      ancestral_town: [NetworkParamType.string],
      ancestral_village: [NetworkParamType.string],
      subscribe_to_newsletter: [NetworkParamType.string],
    },
  },
  getEmailSettings: {
    url: 'member/email-settings/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
    noImpersonation: true,
  },
  changeEmailSettings: {
    url: 'member/email-settings/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    noImpersonation: true,
    params: {
      settings: [NetworkParamType.array],
    },
  },

  isNeedResetPassword: {
    url: 'member/is-need-reset-password/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    noImpersonation: true,
    params: {
      email: [NetworkParamType.string],
    },
  },
  googleOauth2: {
    url: 'social-auth/google-oauth2/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
    noImpersonation: true,
    params: {
      access_token: [NetworkParamType.string],
      entry_point_url: [NetworkParamType.string],
      entry_point_name: [NetworkParamType.string],
      surnames: [NetworkParamType.array],
      referral_code: [NetworkParamType.string],
    },
  },
  googleOauth2Connect: {
    url: 'social-auth/google-oauth2/connect/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
    noImpersonation: true,
    params: {
      access_token: [NetworkParamType.string],
    },
  },
  facebookOauth2: {
    url: 'social-auth/facebook/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
    noImpersonation: true,
    params: {
      access_token: [NetworkParamType.string],
      entry_point_url: [NetworkParamType.string],
      entry_point_name: [NetworkParamType.string],
      surnames: [NetworkParamType.array],
      referral_code: [NetworkParamType.string],
    },
  },
  facebookOauth2Connect: {
    url: 'social-auth/facebook/connect/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
    noImpersonation: true,
    params: {
      access_token: [NetworkParamType.string],
    },
  },
  getNotifications: {
    url: 'notifications/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
  },
  getReferralCode: {
    url: 'referral-code/{code}/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
  },
  translate: {
    url: 'member/translate/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    autoCancel: true,
    params: {
      text: [NetworkParamType.string],
    },
  },
  bannerRecordMatching: {
    url: 'member/banner-record-matching/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
    autoCancel: true,
  },
};
