import {getFullNameCn, getFullNameEn} from '@common/utils/utils.names';

export default {
  familyTreePersonState(state) {
    return state.person;
  },
  familyTreePersonPrimaryFullnameEnState(state, getters) {
    return getFullNameEn(getters.familyTreePersonState.first_names, getters.familyTreePersonState.surnames);
  },
  familyTreePersonPrimaryFullnameCnState(state, getters) {
    return getFullNameCn(getters.familyTreePersonState.first_names, getters.familyTreePersonState.surnames);
  },
  familyTreePersonLoadingState(state) {
    return state.loading;
  },
  familyTreePersonFactsUpdateLoadingState(state) {
    return state.factsUpdateLoading;
  },
  familyTreePersonFilesState(state) {
    return state.personFiles || [];
  },
  familyTreePersonFilesMetaState(state) {
    return state.personFilesMeta || {};
  },
  familyTreePersonFilesLoadingState(state) {
    return state.personFilesLoading;
  },
  familyRelativesByPersonIdState(state) {
    return state.relativesByPersonId || {};
  },
  getFamilyTreePersonRelativesState(state, getters) {
    return personId => {
      let person = getters.familyTreePersonsByIdState[personId] || {};
      /* when person has hidden relatives, some of them are excluded on FT draw due to speed up fetch */
      let personRelatives = person && !person.has_hidden_subtree ? person.relatives : null;
      return personRelatives || state.relativesByPersonId[personId] || {};
    };
  },
  familyTreePersonRelativesLoadingState(state) {
    return state.relativesLoading;
  },
  familyTreePersonOptionsState(state) {
    return state.options;
  },
  familyTreePersonFirstNameTypesState(state) {
    return state.options.first_name_types;
  },
  familyTreePersonSurnameTypesState(state) {
    return state.options.surname_types;
  },
  familyTreePersonGenderOptionsState(state) {
    return state.options.genders;
  },
  familyTreePersonOptionsLoadingState(state) {
    return state.optionsLoading;
  },
  familyTreePersonUpdateLoadingState(state) {
    return state.updateLoading;
  },
  familyTreePersonUpdateErrorsState(state) {
    return state.updateErrors;
  },
  familyTreePersonDeleteLoadingState(state) {
    return state.deleteLoading;
  },
  familyTreePersonDeleteErrorsState(state) {
    return state.deleteErrors;
  },
  familyTreePersonCreateErrorsState(state) {
    return state.createErrors;
  },
  familyTreePersonCreateLoadingState(state) {
    return state.createLoading;
  },
  familyTreePhotosByPersonIdState(state) {
    return state.photosByPersonId;
  },
  familyTreePersonPhotosLoadingState(state) {
    return state.personPhotosLoading;
  },
  familyTreePersonProfilePictureLoadingState(state) {
    return state.profilePictureLoading;
  },
  familyTreePersonDeleteAssetLoadingState(state) {
    return state.deleteAssetLoading;
  },
  userPersonSurnamesState(state) {
    return state.mySurnames;
  },
  userPersonSurnamesLoadingState(state) {
    return state.mySurnamesLoading;
  },
  personAcceptDataLoadingState(state) {
    return state.acceptDataLoading;
  },
};
