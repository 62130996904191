import basicMutations from '@common/store/basic/mutations';

export default {
  setRegistrationWallTextState(state, text) {
    state.registrationWallText = text;
  },
  setLoginPageTextState(state, text) {
    state.loginPageText = text;
  },
  setQuickSidebarPropsState(state, props) {
    state.quickSidebarProps = props;
  },
  ...basicMutations,
};
