import network from '@common/network/network';

export default {
  getReferralCodeAction(context, code) {
    return new Promise((resolve, reject) => {
      network.member
        .getReferralCode({code})
        .then(response => {
          context.commit('setRegisterReferralDataState', response);
          resolve();
        })
        .catch(() => {
          context.commit('setRegisterReferralDataState', null);
          reject();
        });
    });
  },
};
