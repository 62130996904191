import {getRoutePageName} from '@common/utils/analytics/utils.analytics';
import {slugify} from '@common/utils/utils';
import pickBy from 'lodash/pickBy';

export function getRouteNameByUrl(url, router) {
  const resolved = router.resolve(url);
  return resolved && resolved.route && resolved.route.name ? getRoutePageName(resolved.route) : '';
}

export function getPlaceDetailRoute(id, name) {
  const params = {placeId: id};
  const slug = slugify(name);
  if (slug) {
    params.slug = slug;
  }
  return {name: 'place-detail', params};
}

export function getMigrationPlaceDetailRoute(id, name) {
  const params = {placeId: id};
  const slug = slugify(name);
  if (slug) {
    params.slug = slug;
  }
  return {name: 'place-detail', params};
}

export function getCemeteryDetailRoute(id, name) {
  const params = {id};
  const slug = slugify(name);
  if (slug) {
    params.slug = slug;
  }
  return {name: 'cemetery-detail', params};
}

export function getPersonRecordDetailRoute(code, name) {
  const params = {code};
  const slug = slugify(name);
  if (slug) {
    params.slug = slug;
  }
  return {name: 'person-records-detail', params};
}

export function getSubscriptionWallRoute(redirect, recordTitle, fromHints = false) {
  let query = {redirect: redirect, record_title: recordTitle, hints: fromHints};
  return {name: 'subscription-plans', query: pickBy(query, value => !!value || value === '')};
}

export function getSurnameDetailRoute(surname) {
  return {name: 'search-tool-surname-detail', query: {word: surname}};
}

export function getDialectDetailRoute(slug) {
  return {name: 'dialect-detail', params: {slug}};
}

export function getFamilyTreeRoute(store, query) {
  if (store.getters.userIsLoggedInState && !store.getters.userIsSharedState) {
    return {name: 'familytree-details', params: {id: store.getters.activeFamilyTreeIdState}, query};
  }
  return {name: 'familytree-details-my', query};
}

export function getFamilyTreeLibraryRoute(store, query) {
  if (store.getters.userIsLoggedInState && !store.getters.userIsSharedState) {
    return {name: 'familytree-library', params: {id: store.getters.activeFamilyTreeIdState}, query};
  }
  return {name: 'familytree-library-my', query};
}

export function getHintDetailRoute(hintId) {
  return {name: 'hint-review', params: {hintId: hintId}};
}

export function getTreeClanDetailRoute(treeId, clan) {
  return {name: 'familytree-clan-details', params: {id: treeId, name: clan}};
}
