import network from '@common/network/network';

export default {
  fetchPressArticleListAction(context) {
    context.commit('setPressArticlesIsLoadingState', true);
    network.wiki.getPressArticleList().then(response => {
      context.commit('setPressArticlesState', response.objects);
      context.commit('setPressArticlesIsLoadingState', false);
    });
  },
};
