<template>
  <div class="notification-verify-email">
    <div class="heading">
      <div class="heading-6">Verify your email address</div>
      <div><close-icon @click="close"></close-icon></div>
    </div>
    <div class="text text-md">
      We’ve sent you an email with a link to verify your email address. If you can’t find it, please check your spam
      folder.
    </div>
    <div class="text-md">
      <a @click="resendVerifyEmail">Resend verification email</a>
    </div>
  </div>
</template>

<script>
import CloseIcon from 'vue-material-design-icons/Close';
import {mapGetters} from 'vuex';

export default {
  watch: {
    userIsLoggedInState(value) {
      if (!value) {
        this.$emit('close');
      }
    },
    userEmailVerifiedState(value) {
      if (value) {
        this.close();
      }
    },
  },
  computed: {
    ...mapGetters(['userIsLoggedInState', 'userEmailVerifiedState']),
  },
  methods: {
    close() {
      const sixHours = 21600000;
      this.$store.commit('setNextVerifyNotificationTimeState', new Date().getTime() + sixHours);
      this.$emit('close');
    },
    resendVerifyEmail() {
      this.network.member
        .resendVerifyEmail()
        .then(() => {
          this.close();
          this.$router.push({name: 'require-verify', query: {resent: 'true', redirect: this.$route.fullPath}});
        })
        .catch(error => {
          const message = error && error.response && error.response.data && error.response.data.error;
          this.$toasted.error(message || 'Error');
        });
    },
  },
  components: {CloseIcon},
  name: 'NotificationVerifyEmail',
};
</script>

<style scoped lang="scss">
.notification-verify-email {
  padding: 16px;
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .close-icon {
      color: $neutral-400;
      cursor: pointer;
    }
  }

  .text {
    color: $neutral-600;
    margin: 16px 0;
  }

  @media only screen and (min-width: $breakpoint-phablet) {
    max-width: 360px;
  }
}
</style>
