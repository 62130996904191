<template>
  <embeded-google-map :src="src"></embeded-google-map>
</template>

<script>
import EmbededGoogleMap from '@common/elements/maps/EmbededGoogleMap';

export default {
  name: 'BusinessAddressMap',
  computed: {
    src() {
      return "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.8156493529623!2d103.85269749999999!3d1.2845452000000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19092f6e64b9%3A0x658834f7729c4d35!2zMjAgQ29sbHllciBRdWF5LCDQodGW0L3SkdCw0L_Rg9GAIDA0OTMxOQ!5e0!3m2!1sen!2suk!4v1683726410149!5m2!1sen!2suk";
    },
  },
  components: {EmbededGoogleMap},
};
</script>

<style scoped></style>
