export default {
  setClanPedigreesSearchListState(context, zupusData) {
    context.list = zupusData;
  },
  setClanPedigreesSearchMetaState(context, meta) {
    context.meta = meta;
  },
  setClanPedigreesSearchLoadingState(context, loading) {
    context.loading = loading;
  },

  setClanPedigreesSearchFormClanState(context, clan) {
    context.clan = clan;
  },
  setClanPedigreesSearchFormPlaceState(context, place) {
    context.place = place;
  },
  setSearchClanVariantState(state, value) {
    state.searchClanVariant = value;
  },
};
