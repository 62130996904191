function getEmptyForm(formState, data) {
  const form = {};
  for (let key of Object.keys(formState)) {
    form[key] = data && data[key] ? data[key] : null;
  }
  return form;
}

export default {
  setSourceImageByIndexState(context, {image, index}) {
    let images = context.images ? [...context.images] : [];
    images[index] = image;
    context.images = images;
  },
  setSourceImagePreviewState(context, {imageIndex, previewSrc}) {
    if (context.images[imageIndex] && context.images[imageIndex].url) {
      return;
    }
    let images = [...context.images];
    images[imageIndex] = {...images[imageIndex], previewSrc};
    context.images = images;
  },
  setSourceImageOcrByIndexState(context, {ocr, index}) {
    let imagesOcr = context.imagesOcr ? [...context.imagesOcr] : [];
    imagesOcr[index] = ocr;
    context.imagesOcr = imagesOcr;
  },
  setSourceImageLoadingState(context, loading) {
    context.imageLoading = loading;
  },
  setSourceImageOcrLoadingState(context, loading) {
    context.imageOcrLoading = loading;
  },
  clearSourceImagesState(context) {
    context.images = null;
  },
  clearSourceImagesOcrState(context) {
    context.imagesOcr = null;
  },
  clearSourceImagesMatchesVerticesState(context) {
    context.images = context.images.map(p => {
      return p && {...p, matches_vertices: null};
    });
  },

  updateSourceSearchResultsState(context, result) {
    const index = context.imagesSearchResults.findIndex(item => item.priority === result.priority);
    let results = [...context.imagesSearchResults];
    results[index] = result;
    context.imagesSearchResults = results;
  },
  setSourceImagesSearchLoadingState(context, loading) {
    context.imagesSearchLoading = loading;
  },
  setSourceImagesSearchResultsState(context, results) {
    context.imagesSearchResults = results;
  },
  setSourceImagesSearchMetaState(context, meta) {
    context.imagesSearchMeta = meta;
  },

  setSourceDetailsState(context, source) {
    context.source = source;
  },
  mutateSourceDetailsState(context, data) {
    context.source = {...context.source, ...data};
  },
  setSourceDetailsLoadingState(context, loading) {
    context.sourceLoading = loading;
  },

  setCollectionDetailsState(context, collection) {
    context.collection = collection;
  },
  setCollectionDetailsLoadingState(context, loading) {
    context.collectionLoading = loading;
  },

  setDeleteImagesLoadingState(context, loading) {
    context.deleteImagesLoading = loading;
  },

  setSearchAllSourcesOptionsState(context, value) {
    context.searchAllSourcesOptions = value;
  },
  mutateSearchAllSourcesOptionsFacetsState(context, data) {
    if (!context.searchAllSourcesOptions || !context.searchAllSourcesOptions.facets) {
      return;
    }
    const newFacets = {...context.searchAllSourcesOptions.facets};
    for (let key of Object.keys(data)) {
      data[key].forEach(item => {
        newFacets[key] = newFacets[key] || [];
        const index = newFacets[key].findIndex(facet => facet.object_id === item.object_id);
        if (index > -1) {
          newFacets[key].splice(index, 1, item);
        } else {
          newFacets[key].push(item);
        }
      });
    }
    context.searchAllSourcesOptions = {...context.searchAllSourcesOptions, facets: newFacets};
  },
  setSearchAllSourcesOptionsLoadingState(context, value) {
    context.searchAllSourcesOptionsLoading = value;
  },
  setSearchAllSourcesLoadingState(context, value) {
    context.searchAllSourcesLoading = value;
  },
  setSearchAllSourcesListState(context, value) {
    context.searchAllSourcesList = value;
  },
  setSearchAllSourcesMetaState(context, value) {
    context.searchAllSourcesMeta = value;
  },
  mutateSearchAllSourcesFormState(state, data) {
    state.searchAllSourcesForm = {...state.searchAllSourcesForm, ...data};
  },
  clearSearchAllSourcesFormState(state, data) {
    state.searchAllSourcesForm = getEmptyForm(state.searchAllSourcesForm, data);
  },

  mutateOcrLangBySourceIdState(state, {id, lang}) {
    state.ocrLangBySourceId = {...state.ocrLangBySourceId, [id]: lang};
  },

  setSourcesAutocompleteState(state, list) {
    state.sourcesAutocomplete = list;
  },
  setSourcesAutocompleteLoadingState(state, loading) {
    state.sourcesAutocompleteLoading = loading;
  },

  setSearchCategoriesListState(state, list) {
    state.searchCategories = list;
  },
  setSearchCategoriesLoadingState(state, loading) {
    state.searchCategoriesLoading = loading;
  },
  setSearchCategoryState(state, obj) {
    state.searchCategory = obj;
  },
  setSearchCategoryLoadingState(state, loading) {
    state.searchCategoryLoading = loading;
  },
};
