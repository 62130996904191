export default {
  sourceImagesState(state) {
    return state.images || [];
  },
  sourceImagesOcrState(state) {
    return state.imagesOcr || [];
  },
  sourceImageLoadingState(state) {
    return state.imageLoading;
  },
  sourceImageOcrLoadingState(state) {
    return state.imageOcrLoading;
  },

  sourceImagesSearchLoadingState(state) {
    return state.imagesSearchLoading;
  },
  sourceImagesSearchResultsState(state) {
    return state.imagesSearchResults || [];
  },
  sourceImagesSearchMetaState(state) {
    return state.imagesSearchMeta || {};
  },

  sourceDetailsState(context) {
    return context.source || {};
  },
  sourceDetailsLoadingState(context) {
    return context.sourceLoading;
  },

  collectionDetailsState(context) {
    return context.collection || {};
  },
  collectionDetailsLoadingState(context) {
    return context.collectionLoading;
  },

  deleteImagesLoadingState(context) {
    return context.deleteImagesLoading;
  },

  searchAllSourcesOptionsState(context) {
    return context.searchAllSourcesOptions || {};
  },
  searchAllSourcesOptionsLoadingState(context) {
    return context.searchAllSourcesOptionsLoading;
  },
  searchAllSourcesLoadingState(context) {
    return context.searchAllSourcesLoading;
  },
  searchAllSourcesListState(context) {
    return context.searchAllSourcesList;
  },
  searchAllSourcesMetaState(context) {
    return context.searchAllSourcesMeta;
  },
  searchAllSourcesFormState(state) {
    return state.searchAllSourcesForm;
  },

  ocrLangBySourceIdState(state) {
    return state.ocrLangBySourceId;
  },

  sourcesAutocompleteState(state) {
    return state.sourcesAutocomplete;
  },
  sourcesAutocompleteLoadingState(state) {
    return state.sourcesAutocompleteLoading;
  },

  searchCategoriesListState(state) {
    return state.searchCategories || [];
  },
  searchCategoriesLoadingState(state) {
    return state.searchCategoriesLoading;
  },
  searchCategoryState(state) {
    return state.searchCategory || {};
  },
  searchCategoryLoadingState(state) {
    return state.searchCategoryLoading;
  },
};
