export default {
  surnameClansSearchListState(context) {
    return context.clansList || [];
  },
  surnameClansSearchLoadingState(context) {
    return context.clansLoading;
  },

  surnamesListState(context) {
    return context.surnamesList;
  },
  surnamesMetaState(context) {
    return context.surnamesMeta;
  },
  surnamesLoadingState(context) {
    return context.surnamesLoading;
  },

  surnamesSearchFormTextStartState(context) {
    return context.searchTextStart;
  },
  surnamesSearchFormTextState(context) {
    return context.searchText;
  },
  surnameExistsLoadingState(context) {
    return context.surnameExistsLoading;
  },
  surnameExistsState(context) {
    return context.surnameExists;
  },
};
