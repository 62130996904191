import constsBreakpoints from '@common/utils/consts.breakpoints';

export default {
  logoutAction(context) {
    context.dispatch('cleanupTreeStateAction');
    context.commit('clearUserState');
    context.commit('clearActiveFamilyTreeIdState');
    // things that are cached and not re-requested need to be cleaned up on logout
    context.commit('clearFamilyTreeLibraryState');
    context.commit('setClanPedigreeDetailsState', null);
    context.commit('setFamilyTreePersonState', null);
    context.commit('setFamilyTreeLineageState', null);
    context.commit('setSubscriptionsState', []);
    context.commit('setFamilyTreeListState', []);
    context.commit('setFamilyTreeHintsListState', []);
    context.commit('setFamilyTreeHintsMetaState', {});
  },
  loginCleanupAction(context) {
    context.dispatch('cleanupTreeStateAction');
    context.commit('clearActiveFamilyTreeIdState');
    // cleanup searches, so that subscriber sees the concealed data
    context.commit('clearSearchAllRecordsFormState');
    context.commit('clearSourcesTextSearchFormState');
    context.commit('clearSearchAllSourcesFormState');
  },
  autofocusAction(context, focusableElement) {
    if (context.getters.windowWidthState > constsBreakpoints.tablet) {
      focusableElement.focus();
    }
  },
};
