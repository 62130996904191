import {getCookie} from '@common/utils/cookies';

let hubspot = (window._hsq = window._hsq || []);

function post(url, data) {
  return fetch(url, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(data),
  });
}

const AnalyticsHubspotHandler = (function () {
  const instance = {};

  instance.identify = email => {
    hubspot.push(['identify', {email: email}]);
  };
  instance.trackPageView = path => {
    hubspot.push(['setPath', path]);
    hubspot.push(['trackPageView']);
  };

  instance.submitFormContactUs = (fields, pageUri, pageName) => {
    const fieldsList = Object.keys(fields).map(key => ({name: key, value: fields[key]}));
    return instance.submitForm(process.env.VUE_APP_HUBSPOT_CONTACT_FORM_ID, fieldsList, pageUri, pageName);
  };
  instance.submitForm = (formId, fields, pageUri, pageName) => {
    if (!process.env.VUE_APP_HUBSPOT_API_BASE) {
      return;
    }
    const hutk = getCookie('hubspotutk');
    const data = {
      fields: fields,
      context: {pageUri, pageName, hutk},
      legalConsentOptions: {
        consent: {consentToProcess: true, text: 'I agree to allow to store and process my personal data.'},
      },
    };
    const url = `${process.env.VUE_APP_HUBSPOT_API_BASE}submissions/v3/integration/submit/${process.env.VUE_APP_HUBSPOT_PORTAL_ID}/${formId}`;
    return post(url, data);
  };

  return instance;
})();

export default AnalyticsHubspotHandler;
