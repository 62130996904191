import dictionaryServices from '@common/network/dictionary/services';

export default {
  findDictionaryWordAction(context, wordCn) {
    context.commit('setDictionaryWordLoadingState', {[wordCn]: true});
    return new Promise((resolve, reject) => {
      dictionaryServices
        .findWord(wordCn)
        .then(response => {
          for (let word of response.data.objects) {
            context.commit('addWordToDictionaryState', word);
          }
          resolve(response.data.objects);
        })
        .finally(() => {
          context.commit('setDictionaryWordLoadingState', {[wordCn]: false});
        });
    });
  },
};
