export default {
  setFamilyTreeSavedMentionsState(state, data) {
    state.savedMentions = data;
  },
  setFamilyTreeSavedMentionsMetaState(state, data) {
    state.savedMentionsMeta = data;
  },
  setFamilyTreeSavedMentionsLoadingState(state, loading) {
    state.savedMentionsLoading = loading;
  },

  setFamilyTreePersonSavedMentionsState(state, data) {
    state.savedMentionsPerson = data;
  },
  setFamilyTreePersonSavedMentionsMetaState(state, data) {
    state.savedMentionsPersonMeta = data;
  },
  setFamilyTreePersonSavedMentionsLoadingState(state, loading) {
    state.savedMentionsPersonLoading = loading;
  },

  setFamilyTreeSavedMentionsPersonIdsState(state, data) {
    state.savedMentionsPersonIds = data;
  },
};
