export default {
  clansSearchLoadingState(state) {
    return state.clansSearchLoading;
  },
  clansSearchState(state) {
    return state.clansSearch || [];
  },
  clansSearchStartTimeState(state) {
    return state.clansSearchStartTime || 0;
  },

  clansListState(state) {
    return state.clansList || [];
  },
  clansListLoadingState(state) {
    return state.clansListLoading;
  },

  clanDetailedState(state) {
    return state.clanDetailed;
  },
  clanDetailedLoadingState(state) {
    return state.clanDetailedLoading;
  },
  spellingDetailedState(state) {
    return state.spellingDetailed;
  },
  spellingDetailedLoadingState(state) {
    return state.spellingDetailedLoading;
  },
  spellingSearchState(state) {
    return state.spellingSearch;
  },
  spellingSearchMetaState(state) {
    return state.spellingSearchMeta;
  },
  spellingSearchLoadingState(state) {
    return state.spellingSearchLoading;
  },

  dialectState(state) {
    return state.dialect || {};
  },
  dialectLoadingState(state) {
    return state.dialectLoading;
  },
};
