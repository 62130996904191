import {NetworkAPIVersion, NetworkParamType} from './network.utils';

export default {
  createParenthood: {
    url: 'parenthood/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    authorization: true,
    params: {
      parent_ids: [NetworkParamType.array],
      child_ids: [NetworkParamType.array],
    },
  },
  createCouple: {
    url: 'couple/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    authorization: true,
    params: {
      person1_id: [NetworkParamType.string],
      person2_id: [NetworkParamType.string],
      marriage_place: [NetworkParamType.object],
      marriage_date: [NetworkParamType.object],
      is_divorced: [NetworkParamType.boolean],
      divorce_place: [NetworkParamType.object],
      divorce_date: [NetworkParamType.object],
    },
  },
  updateCouple: {
    url: 'couple/{id}/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'patch',
    authorization: true,
    params: {
      marriage_place: [NetworkParamType.object],
      marriage_date: [NetworkParamType.object],
      is_divorced: [NetworkParamType.boolean],
      divorce_place: [NetworkParamType.object],
      divorce_date: [NetworkParamType.object],
    },
  },
};
